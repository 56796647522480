<template>
    <div class="content">
        <!-- 导航栏 -->
        <div style="height: 100%;width: 100%;overflow-y: scroll">
            <div style="width: 100%;text-align: center;height: 40px;line-height: 40px;font-size: 20px;color: black;font-weight: 700;margin-top: 40px;margin-bottom: 20px">用户协议</div>
            <div class="paragraph2">
                发布日期：2022年5月19日
            </div>
            <div class="paragraph2">
                生效日期：2022年5月19日
            </div>
            <div style="clear: both"></div>
            <div class="subTitle">一、特别提示</div>
            <div class="paragraph">
                共生地球是由长光卫星技术股份有限公司（以下简称长光卫星）开发。请您务必认真阅读并理解用户协议中各项条款，包括免除或者限制长光卫星责任及对用户的权利限制条款。为使用本软件，您应当点击“同意”按钮以同意用户协议下的全部条款。如果您点击“不同意”按钮，您将无权使用本软件。一旦您点击“同意”按钮即表示您已经理解并完全接受用户协议下的全部条款，用户协议即构成您（即“用户”）和长光卫星之间的具有法律约束力的协议。
            </div>
            <div class="paragraph">
                共生地球非常重视您的个人信息和隐私的保护，将按照《共生地球隐私政策》中所公布的政策在具体服务中进行隐私及个人信息的收集、存储、使用、披露等活动。您在注册共生地球账号前，应仔细阅读《共生地球隐私政策》。
            </div>
            <div class="paragraph">
                本协议约定长光卫星与用户之间关于“共生地球”服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本服务的个人。本协议可由长光卫星随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本网页中查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用共生地球提供的服务，用户继续使用共生地球提供的服务将被视为接受修改后的协议。
            </div>
            <div class="paragraph">
                本协议适用于网页端、移动客户端（包括IOS、安卓及已有或未来将新增的任何其他移动客户端）等各类平台或媒介中共生地球所提供的各项服务。您知悉并理解，为享受上述相关服务，您必须自行提供相关上网设备（如：个人电脑、手机、平板电脑或其他设备）并自行负担相关网络使用和商品服务支付所产生的有关费用
            </div>
            <div class="subTitle">二、账号的注册</div>
            <div class="paragraph">
                1、用户在使用本服务前需要注册一个“共生地球”账号。“共生地球”账号应当使用手机号码绑定注册，请用户使用尚未与“共生地球”账号绑定的手机号码，以及未被共生地球根据本协议封禁的手机号码注册“共生地球”账号。共生地球可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。
            </div>
            <div class="paragraph">
                2、如果注册申请者有被共生地球封禁的先例或涉嫌虚假注册及滥用他人名义注册，及其他不能得到许可的理由，共生地球将拒绝其注册申请。
            </div>
            <div class="paragraph">
                3、在用户注册及使用本服务时，共生地球需要搜集能识别用户身份的个人信息以便共生地球可以在必要时联系用户，或为用户提供更好的使用体验。共生地球搜集的信息包括但不限于用户的邮箱、手机号；共生地球同意对这些信息的使用将受限于第三条用户个人隐私信息保护的约束。
            </div>
            <div class="subTitle">三、账户安全</div>
            <div class="paragraph">
                1、用户一旦注册成功，成为共生地球的用户，将得到一个用户名和密码，并有权利使用自己的用户名及密码随时登录共生地球。
            </div>
            <div class="paragraph">
                2、用户对用户名和密码的安全负全部责任，同时对以其用户名进行的所有活动和事件负全责。
            </div>
            <div class="paragraph">
                3、用户不得以任何形式擅自转让或授权他人使用自己的共生地球用户名。
            </div>
            <div class="paragraph">
                4、如果用户泄漏了密码，有可能导致不利的法律后果，因此不管任何原因导致用户的密码安全受到威胁，应该立即和长光卫星客服人员取得联系，否则后果自负。
            </div>
            <div class="subTitle">四、用户声明与保证</div>
            <div class="paragraph">
                1、用户承诺其为具有完全民事行为能力的民事主体，且具有达成交易履行其义务的能力。
            </div>
            <div class="paragraph">
                2、用户有义务在注册时提供自己的真实资料，并保证诸如手机号码、所在地区等内容的有效性及安全性，保证共生地球工作人员可以通过上述联系方式与用户取得联系。同时，用户也有义务在相关资料实际变更时及时更新有关注册资料。
            </div>
            <div class="paragraph">
                3、用户通过使用共生地球的过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用账号或本服务所产生的内容，不得违反国家相关法律制度，包含但不限于如下原则：
            </div>
            <div class="paragraph">
                （1）反对宪法所确定的基本原则的；
            </div>
            <div class="paragraph">
                （2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </div>
            <div class="paragraph">
                （3）损害国家荣誉和利益的；
            </div>
            <div class="paragraph">
                （4）煽动民族仇恨、民族歧视，破坏民族团结的；
            </div>
            <div class="paragraph">
                （5）破坏国家宗教政策，宣扬邪教和封建迷信的；
            </div>
            <div class="paragraph">
                （6）散布谣言，扰乱社会秩序，破坏社会稳定的；
            </div>
            <div class="paragraph">
                （7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </div>
            <div class="paragraph">
                （8）侮辱或者诽谤他人，侵害他人合法权益的；
            </div>
            <div class="paragraph">
                （9）含有法律、行政法规禁止的其他内容的。
            </div>
            <div class="paragraph">
                4、用户不得利用“共生地球”账号或本服务制作、上载、复制、发布、传播下干扰“共生地球”正常运营，以及侵犯其他用户或第三方合法权益的内容：
            </div>
            <div class="paragraph">
                （1）含有任何性或性暗示的；
            </div>
            <div class="paragraph">
                （2）含有辱骂、恐吓、威胁内容的；
            </div>
            <div class="paragraph">
                （3）含有骚扰、垃圾广告、恶意信息、诱骗信息的；
            </div>
            <div class="paragraph">
                （4）涉及他人隐私、个人信息或资料的；
            </div>
            <div class="paragraph">
                （5）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
            </div>
            <div class="paragraph">
                （6）含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
            </div>
            <div class="subTitle">五、服务内容</div>
            <div class="paragraph">
                1、共生地球具体服务内容由长光卫星根据实际情况提供，包括但不限于：
            </div>
            <div class="paragraph">
                （1）数据接入、组织、管理、发布、浏览、展示、应用、分享服务等；
            </div>
            <div class="paragraph">
                （2）在线咨询服务（服务时间：工作日8：30-17：30）；
            </div>
            <div class="paragraph">
                （3）服务商收入结算和用户支付费用：具体金额以共生地球产生的统计数据为准。
            </div>
            <div class="paragraph">
                2、长光卫星有权维护或删除用户发布/传播的涉嫌违法或违反社会主义精神文明，或者被长光卫星认为不妥当的内容（包括但不限于文字、语音、图片、视频、图文等）。
            </div>
            <div class="subTitle">六、协议的终止</div>
            <div class="paragraph">
                1、在下列情况下，长光卫星有权终止向用户提供服务：
            </div>
            <div class="paragraph">
                （1）在用户违反本服务协议相关规定时，长光卫星有权终止向该用户提供服务。如该用户再一次直接或间接或以他人名义注册为用户的，一经发现，长光卫星有权直接单方面终止向该用户提供服务；
            </div>
            <div class="paragraph">
                （2）如长光卫星通过用户提供的信息与用户联系时，发现用户在注册时填写的联系方式已不存在或无法接通，长光卫星以其它联系方式通知用户更改，而用户在三个工作日内仍未能提供新的联系方式，长光卫星有权终止向该用户提供服务；
            </div>
            <div class="paragraph">
                （3）用户不得通过程序或人工方式进行刷量或作弊，若发现用户有作弊行为，共生地球将立即终止服务；
            </div>
            <div class="paragraph">
                （4）一旦长光卫星发现用户提供的数据或信息中含有虚假内容，长光卫星有权随时终止向该用户提供服务；
            </div>
            <div class="paragraph">
                （5）本服务条款终止或更新时，用户明示不愿接受新的服务条款；
            </div>
            <div class="paragraph">
                （6）其它长光卫星认为需终止服务的情况。
            </div>
            <div class="paragraph">
                2、服务终止后，共生地球没有义务为用户保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给用户或第三方。
            </div>
            <div class="subTitle">七、服务的变更、中断</div>

            <div class="paragraph">
                鉴于网络服务的特殊性，用户需同意共生地球会变更、中断部分或全部的网络服务，并删除（不再保存）用户在使用过程中提交的任何资料，而无需通知用户，也无需对任何用户或任何第三方承担任何责任。
            </div>

            <div class="subTitle">八、服务条款修改</div>
            <div class="paragraph">
                1、长光卫星有权随时修改本服务条款的任何内容，一旦本服务条款的任何内容发生变动，长光卫星将会通过适当方式向用户提示修改内容。
            </div>
            <div class="paragraph">
                2、如果不同意长光卫星对本服务条款所做的修改，用户有权停止使用网络服务。
            </div>
            <div class="paragraph">
                3、如果用户继续使用网络服务，则视为用户接受长光卫星对本服务条款所做的修改。
            </div>
            <div class="subTitle">九、免责与赔偿声明</div>
            <div class="paragraph">
                1、若共生地球需要定期或不定期地对提供网络服务的平台进行检测或者更新，如因此类情况而造成网络服务在合理时间内的中断，长光卫星无需为此承担任何责任。已经明示其服务提供方式发生变更并提醒用户应当注意事项，用户未按要求操作所产生的一切后果由用户自行承担。
            </div>
            <div class="paragraph">
                2、用户明确同意其使用共生地球所存在的风险将完全由其自己承担，因其使用共生地球而产生的一切后果也由其自己承担。
            </div>
            <div class="paragraph">
                3、用户同意通过共生地球上传、发布的任何内容导致的知识产权侵权问题，包括但不限于图片、视频、文字、字体等，您将承担全部责任；如长光卫星公司或其他服务提供方知识产权维权而产生损失，您将等额赔偿。
            </div>
            <div class="paragraph">
                4、用户可通过共生地球有关网络服务获得第三方的某些内容，或者共生地球可能为方便用户而提供通往第三方网站的链接，但共生地球不负责检查或评估任何该等第三方材料、产品、服务或网站内容的准确性，并且，共生地球对此不作保证、不承担任何责任、也不负有任何义务。用户对此自行加以判断，并承担因使用该等内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。本软件和/或本网络服务所展现的页面上可能存在其他网页或广告等的链接，共生地球对其他任何网站的内容、隐私政策、运营或经营这些网站的第三方的行为无法控制且概不负责。用户经由共生地球服务与广告商进行通讯联系或商业往来或参与促销活动，完全属于用户与广告商之间的行为，与共生地球没有任何关系，若因商业行为所产生之任何损害或损失，共生地球不承担任何责任。在点击这些网站提供的链接之前，请用户慎重考虑，保护自身的权益及安全。
            </div>
            <div class="paragraph">
                5、用户同意保障和维护长光卫星及其他用户的利益，由于用户在使用共生地球有违法、不真实、不正当、侵犯第三方合法权益的行为，或用户违反本协议项下的任何条款而给长光卫星及任何其他第三方造成损失，用户同意承担由此造成的损害赔偿责任。
            </div>
            <div class="paragraph">
                6、就下列相关事宜的发生，共生地球在此声明不承担任何法律责任：
            </div>
            <div class="paragraph">
                （1）用户应遵守国家的有关法律、法规和行政规章制度。如有用户违反国家法律法规或本用户协议，共生地球有权停止向用户提供任务而不需要承担任何责任；如用户的不法使用行为导致共生地球遭受任何损害或者遭受任何来自第三方的纠纷、诉讼、索赔要求等，用户须向共生地球赔偿相应的损失，用户需对其违反用户协议所产生的一切后果承担全部法律责任；
            </div>
            <div class="paragraph">
                （2）由于您将用户帐号告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄露，或其他非共生地球方面原因导致的个人信息的泄露；
            </div>
            <div class="paragraph">
                （3）根据法律规定或政府相关政策要求提供您的个人信息；
            </div>
            <div class="paragraph">
                （4）任何通过黑客攻击、电脑病毒侵入等非法截取、访问等方式从我们网站上获取的信息；
            </div>
            <div class="paragraph">
                （5）以及因台风、地震、海啸、洪水、停电、战争、恐怖袭击等国际通用不可抗力因素导致的任何后果；
            </div>
            <div class="paragraph">
                （6）因共生地球向用户提供的免费服务本身及其引发的任何损失及或风险；
            </div>
            <div class="paragraph">
                （7）其他因网络信息或用户行为引起的风险。
            </div>
            <div class="subTitle">十、隐私声明</div>
            <div class="paragraph">
                1、保护用户的隐私是共生地球的一项基本政策。
            </div>
            <div class="paragraph">
                2、适用于用户的隐私政策参见《共生地球隐私政策》。
            </div>
            <div class="subTitle">十一、其他</div>
            <div class="paragraph">
                1、长光卫星郑重提醒用户注意本协议中免除长光卫星责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。
            </div>
            <div class="paragraph">
                2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和长光卫星之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交长光卫星住所地有管辖权的人民法院管辖。
            </div>
            <div class="paragraph">
                3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。
            </div>
            <div class="paragraph">
                4、本协议最终解释权归长光卫星所有，并且保留一切解释和修改的权力。
            </div>
            <div style="width: 100%;height: 80px;"></div>
        </div>



    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
    }
</script>

<style>
    .content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:white;
        z-index: -1;
    }

    .subTitle{
        width: 82%;
        margin: 0 auto;
        font-size: 16px;
        color: black;
        text-align: left;
        line-height: 20px;
        margin-top: 20px;
        font-weight: 700;
    }

    .paragraph{
        width: 82%;
        margin: 0 auto;
        font-size: 14px;
        color: #7C7B7B;
        text-align: left;
        line-height: 18px;
        text-indent: 30px;
        margin-top: 10px;
    }

    .paragraph2{
        width: 82%;
        float: right;
        margin-right: 9%;
        font-size: 14px;
        color: #7C7B7B;
        text-align: right;
        line-height: 18px;
        text-indent: 30px;
        margin-top: 5px;
        clear: both;
    }
</style>
