<template>
    <div ref="routeMarker">
        <div v-if="markerInfo.properties.markType === '标记点'">
            <!--圆点-->
            <div class="routeMarkerIcon-circle" :style="{'background':'#'+markerInfo.properties.color.substr(2).slice(2) + markerInfo.properties.color.substr(2).slice(0,2)}"></div>
            <!--标题-->
            <div class="routeMarker_title" style="bottom:-29px;">
                <p>{{markerInfo.properties.markName}}</p>
            </div>
        </div>

        <div v-if="markerInfo.properties.markType === '拍摄点'">
            <!--圆点-->
            <div class="routeMarkerPoint-circle" @click="clickRouteMarkerPoint"
                 :style="{'background':'#'+markerInfo.properties.color.substr(2).slice(2) + markerInfo.properties.color.substr(2).slice(0,2)}">
                <p>{{markerInfo.properties.serialNumber}}</p>
            </div>
            <!--标题-->
            <div class="routeMarker_title">
                <p>{{markerInfo.properties.markName}}</p>
            </div>
            <!--气泡 主页面里 使用popup形式-->
            <!--<div v-if="currMarkerId === markerInfo.properties.markerId" class="routeMarkerPoint-bubble">-->
                <!--<img :src="markerInfo.properties.markThumb"/>-->
            <!--</div>-->
        </div>

    </div>

</template>

<script>
    export default {
        name: "RouteMark",
        props: ['markerInfo'],
    }
</script>

<style scoped>
    /*标记点 圆点*/
    .routeMarkerIcon-circle{
        width:11px;
        height:11px;
        /*background:#579C22;*/
        border-radius:50%;
        border:2px solid #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
    }


    /*!*拍摄点 气泡*!*/
    /*.routeMarkerPoint-bubble{*/
        /*position:absolute;*/
        /*top:-70px;*/
        /*left:-19px;*/
        /*width: 60px;*/
        /*height: 60px;*/
        /*border: 2px solid #ffffff;*/
        /*border-radius: 50%;*/
        /*box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.25);*/
        /*background-color: white;*/
        /*z-index:1;*/
    /*}*/
    /*.routeMarkerPoint-bubble:after{*/
        /*content:'';*/
        /*position:absolute;*/
        /*bottom:-16px;*/
        /*left:calc(50% - 12px);*/
        /*width:0;*/
        /*height:0;*/
        /*display:block;*/
        /*border-style:solid;*/
        /*border-width:8px 12px;*/
        /*border-color:#ffffff transparent transparent transparent;*/
        /*z-index: -1;*/
    /*}*/
    /*.routeMarkerPoint-bubble img{*/
        /*position:absolute;*/
        /*top:0;*/
        /*left:0;*/
        /*border-radius: 50%;*/
        /*width: 100%;*/
        /*height: 100%;*/
        /*object-fit:cover;*/
    /*}*/
    /*拍摄点圆点*/
    .routeMarkerPoint-circle{
        width:22px;
        height:22px;
        /*background:#579C22;*/
        border-radius:50%;
        border:3px solid #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        font-size:16px;
        font-weight:bold;
        color:#ffffff;
        text-align: center;
    }
    .routeMarkerPoint-circle p{
        line-height:22px;
        margin:0;
    }


    /*通用 标题*/
    .routeMarker_title{
        position:absolute;
        bottom:-25px;
        left:50%;
        transform: translate(-50%);
        height:21px;
        background:rgba(0,0,0,0.51);
        border-radius:13px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.50);
        padding:0 8px;
        font-size:12px;
        color:#ffffff;
        text-align:center;
    }
    .routeMarker_title p{
        line-height:21px;
        margin:0;
        white-space:nowrap;
    }

</style>