<template>

  <div class="markDetailPage">
<!--    <div class="markDetailPage-title">-->
<!--      <p style="line-height:66px;">拍摄点详情</p>-->
<!--    </div>-->


    <!--轮播图-->
    <div class="markDetailPage-swiper">
      <swiper ref="markDetailSwiper" @slideChange="onMarkDetailSwiperChange()" :options="swiperOption" style="height:100%;text-align:center;">
        <swiper-slide v-for="(item, index) in picList" :key="index">
          <img @click="bigImageShow=true;" :src="item" alt=""/>
        </swiper-slide>
      </swiper>
      <div class="markDetailPage-swiper-number">
        <p style="line-height:25px;">{{swiperIndex + 1}}/{{picList.length}}</p>
      </div>
    </div>

    <!--介绍-->
    <div class="markDetailPage-describe">
      <p v-html="describeString"></p>
    </div>

    <!--信息栏-->
    <div v-if="basicInfo.length !== 0" class="markDetailPage-info">
      <div style="height:20px;padding:0 13px;margin-bottom:5px;">
        <div style="width:5px;height:100%;background:#675CB5;margin-right:5px;float:left;"></div>
        <p style="font-size:16px;font-weight:bold;color:#323232;;line-height:20px;">基本信息</p>
      </div>
      <div v-for="(item,index) in basicInfo" :key="'basicInfo'+index" class="markDetailPage-infoItem">
        <p style="width:90px;font-size:14px;color:#808080;line-height:50px;float:left;">{{item.name}}</p>
        <p @click="jumpToOperation(item)" :style="{'color':(item.name !== '地址' && item.name !== '电话') ? '#414141' : '#675CB5'}" style="width:calc(100% - 90px);padding:15px 0;font-size:14px;font-weight:bold;color:#414141;float:left;">{{item.value}}</p>
      </div>
    </div>
    <div v-if="customInfo.length !== 0" class="markDetailPage-info">
      <div style="height:20px;padding:0 13px;margin-bottom:5px;">
        <div style="width:5px;height:100%;background:#675CB5;margin-right:5px;float:left;"></div>
        <p style="font-size:16px;font-weight:bold;color:#323232;;line-height:20px;">{{markDetail.customInfo.title}}</p>
      </div>
      <div v-for="(item,index) in customInfo" :key="'customInfo'+index" class="markDetailPage-infoItem">
        <p style="width:90px;font-size:14px;color:#808080;line-height:50px;float:left;">{{item.name}}</p>
        <p style="width:calc(100% - 90px);padding:15px 0;font-size:14px;color:#414141;font-weight:bold;float:left;">{{item.value}}</p>
      </div>
    </div>


    <!--全屏看大图-->
    <div v-if="bigImageShow" @click="bigImageShow=false;" style="position:fixed;top:0;left:0;width:100%;height:100%;background:#000000;z-index:99;background-repeat: no-repeat;background-position: center;background-size:contain;"
         :style="{'background-image':'url(\'' + picList[swiperIndex] + '\')'}">
    </div>

  </div>






</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "MarkDetailPage",
  components:{
    swiper,
    swiperSlide,
  },
  data(){
    return{

      markId:"",
      markDetail:{},
      describeString:"",
      picList:[],
      basicInfo:[],
      customInfo:[],

      //轮播图属性
      swiperOption: {
        speed: 300,
        loop: true,
      },
      swiperIndex:0,

      //大图
      bigImageShow:false,


    }


  },
  methods:{

    getMarkDetail(){
      this.$http.get('/earthApi/bestRouteShoot/getRouteShoot?routeShootId='+this.markId).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          this.markDetail = response.body.data;
          //介绍处理换行
          let describeList = this.markDetail.routeShootDescribe.split('\n');
          this.describeString = "";
          for(let i=0;i<describeList.length;i++){
            if(i===0){
              this.describeString = describeList[i];
            }
            else{
              this.describeString = this.describeString + "<br/>" + describeList[i];
            }
          }

          this.picList = this.markDetail.routeShootPicList
          this.basicInfo = [];
          for(let i=0;i<this.markDetail.basicInfo.info.length;i++){
            if(this.markDetail.basicInfo.info[i].value !== ""){
              this.basicInfo.push(this.markDetail.basicInfo.info[i]);
            }
          }

          if(this.markDetail.customInfo.title !== undefined){
            this.customInfo = this.markDetail.customInfo.info;
          }
          else{
            this.customInfo = []
          }


          document.title = this.markDetail.routeShootTitle;
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })


    },

    //轮播图变化
    onMarkDetailSwiperChange(){
      this.swiperIndex  = this.$refs.markDetailSwiper.swiper.activeIndex
    },


    //跳转地图 电话等
    jumpToOperation(item){
      if(item.name === "电话"){
        window.location.href = 'tel:'+item.value;
      }
      else if(item.name === "地址"){
        window.location.href = 'http://api.map.baidu.com/geocoder?address=' + item.value + '&output=html&src=webapp.baidu.openAPIdemo'
      }
    },


  },

  mounted(){
    this.markId = this.$route.query.markId
    this.getMarkDetail();


  }
}
</script>

<style scoped>
.markDetailPage{
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F3F3F3;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.markDetailPage-title{
  width:100%;
  height:66px;
  background:#ffffff;
  font-size:16px;
  color:#000000;
  font-weight:bold;
  text-align:center;
}
.markDetailPage-swiper{
  position:relative;
  width:100%;
  height:220px;
  background:#ffffff;
  padding-top:30px;
}
.markDetailPage-swiper img{
  height:100%;
}
.markDetailPage-swiper-number{
  position:absolute;
  z-index:1;
  bottom:8px;
  right:9px;
  width:51px;
  height:25px;
  background:rgba(0,0,0,0.4);
  border-radius:12.5px;
  font-size:14px;
  color:#ffffff;
  text-align:center;
}

.markDetailPage-describe{
  padding:16px 23px 30px 23px;
  background:#ffffff;
  font-size:14px;
  color:#343434;
}

.markDetailPage-info{
  padding-top:16px;
  margin-top:5px;
  background:#ffffff;
}
.markDetailPage-infoItem{
  min-height:50px;
  padding:0 23px;
  border-bottom:1px solid #E9E9E9;
  overflow:hidden;
}


</style>

<style>
.markDetailPage p{
  margin:0;
}
</style>