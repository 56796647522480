<template>
    <div id="yijing">
        <l-map ref="map" style="z-index: 0" :maxBounds="maxBounds" :options="option" :zoom="zoom" :center="center">
            <l-tile-layer v-if="mapShow" ref="mapLayer" url="https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf" :tms="true" />
            <l-wms-tile-layer
                v-if="mallLayerShow"
                base-url="/geo/geoserver/test1130/wms"
                layers="test1130:market1"
                format="image/png"
                :transparent="true"
                :opacity="opacity"
            />
            <template v-for="(item,index) in mallList">
                <l-circle-marker v-if="currentMall != item && mallLayerShow" :key="'mall'+index" :latLng="[item.lat,item.lng]" @click="currentMall=item; sendMallInfo()" :radius="6" :options="circleMarkerStyle"></l-circle-marker>
            </template>
            <l-circle-marker v-if="currentMall != '' && mallLayerShow" :latLng="[currentMall.lat,currentMall.lng]" :radius="9" @click="sendMallInfo()"  :options="selectedCircleMarkerStyle"></l-circle-marker>
            <l-wms-tile-layer
                v-if="popLayerShow"
                base-url="/geo/geoserver/test1130/wms"
                layers="test1130:population"
                format="image/png"
                :transparent="true"
                :opacity="opacity"
            />

            <template v-for="(item,index) in railList">
                <l-polyline v-if="currentRail != item && railLayerShow" :key="index" :latLngs="wktToPolyline(item.geom)" @click="currentRail=item; sendRailInfo()" :options="railStyleList[index]"></l-polyline>
            </template>
            <l-polyline v-if="currentRail != '' && railLayerShow" pane="popupPane" :latLngs="wktToPolyline(currentRail.geom)" @click="sendRailInfo()" :options="selectedRailStyle"></l-polyline>
        </l-map>
        <div id="scanner" :style="scannerShow? 'transform: translateY(2000px);transition: transform 4s;':''"></div>
        <div v-if="mallLayerShow||popLayerShow" class="opacitySlide">
            <input id="range" type="range" max="1" min="0" step="0.01" :value="opacity" @input="changeOpacity($event)">
        </div>
        <div v-if="tipsShow&&share==0" id="tips">
            点击圆圈或线路，查看详细信息
        </div>
        <TopShareBar v-if="share ==1" />
    </div>
</template>

<script>
import L from "leaflet";
import {LMap, LTileLayer, LCircleMarker,LPolyline,LWMSTileLayer} from "vue2-leaflet";
import TopShareBar from '@/components/TopShareBar';

export default {
    name: "Yijing",
    components: {
        LMap,
        LTileLayer,
        LCircleMarker,
        LPolyline,
        'l-wms-tile-layer': LWMSTileLayer,
        TopShareBar
    },
    data() {
        return{
            zoom: 13,
            center: L.latLng(31.21, 121.48),
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas(), maxZoom: 18, minZoom: 5, doubleClickZoom: false, tapHold: false},
            currentPanel: '',
            mall: false,
            mallLayerShow: false,
            pop: false,
            popLayerShow: false,
            rail: false,
            railLayerShow: false,
            moreInfoPanelUp: false,
            currentUrl: 'https://xz.changjing.com.cn/',
            browserShow: false,
            legendList:[
                {color:"#FED79E", text:"1 - 2"},
                {color:"#FFBD63", text:"3 - 5"},
                {color:"#FF9300", text:"6 - 10"},
                {color:"#D78617", text:"11 - 15"},
                {color:"#FF6431", text:"16 - 25"},
                {color:"#FF3500", text:"> 25"},
            ],
            legendList2:[
                {color:"#BFC9FF", text:"1-500"},
                {color:"#7FD3FF", text:"500-1000"},
                {color:"#02C3FF", text:"1000-2000"},
                {color:"#4088FF", text:"2000-3000"},
                {color:"#0174FF", text:"3000-5000"},
                {color:"#2030FF", text:">5000"},
            ],
            scannerShow: false,
            mallList:[],
            currentMall:{
                "name": "",
                "address": "",
                "adcode": "",
                "cityName": "",
                "startTime": "",
                "commercialArea": 0,
                "floor": 0,
                "lng": 0,
                "lat": 0
            },
            circleMarkerStyle: {
                color: '#ED7D31',
                weight: 0,
                fillOpacity: 1,
                fillColor: '#ED7D31',
            },
            selectedCircleMarkerStyle: {
                color: '#2E63D4',
                weight: 3,
                fillOpacity: 1,
                fillColor: '#ED7D31',
            },
            selectedRailStyle:{
                color:'#FF0000',
                weight: 12
            },
            railList: [],
            currentRail: {
                "name": "",
                "adcode": "",
                "cityName": "",
                "startStation": "",
                "endStation": "",
                "openingTime": "0000--0000",
                "stationTotal": 0,
                "length": 0,
                "geom": "LINESTRING(0 0)"
            },
            opacity: 0.5,
            tipsShow: false,
            redPoint: false,
            share: 0,
            mapShow: false,
        }
    },
    methods:{
        sendMallInfo(){
            if(window["Mall"]){
                window["Mall"].postMessage(this.currentMall.name + '!'+'行政区划：黄浦区\n地       址：'+ this.currentMall.address+'\n建筑面积：' + this.currentMall.commercialArea + '万平⽅⽶\n层       高：' + this.currentMall.floor + '层');
            }
        },
        sendRailInfo(){
            if(window["Rail"]){
                window["Rail"].postMessage(this.currentRail.name + '!'+'方向：'+ this.currentRail.startStation +' (起) —— ' + this.currentRail.endStation +'(终)\n运营时间\n起点站时间：'+ this.openingTimeFormatter(this.currentRail.openingTime) +'\n终点站时间：' + this.openingTimeFormatter(this.currentRail.openingTime) + '\n站点总数：' + this.currentRail.stationTotal + ' 总长度：' + this.currentRail.length.toFixed(1) + 'km');
            }
        },
        toggleMoreInfoPanel(){
            this.moreInfoPanelUp = !this.moreInfoPanelUp;
            localStorage.setItem('Yijing','1');
            this.redPoint = false
        },
        changeOpacity(e){
            this.opacity = parseFloat(e.target.value);
        },
        openingTimeFormatter(val) {
            let tmp = ''
            if(val.length<10){
                tmp = '0530--2230'
            } else {
                tmp = val
            }
            return '首' + tmp.substring(0,2) + ':' + tmp.substring(2,4) + ' 末' + tmp.substring(6,8) + ':' + tmp.substring(8,10)
        },
        getMallList() {
            this.$http.get('/yijingApi/changguang-service/v1/mall?adcode=310101', {headers:{'apikey': 'changguang'}}).then(response => {
                this.mallList = response.body.metadata.infos
            })
        },
        getRailList() {
            this.$http.get('/yijingApi/changguang-service/v1/rail-line?adcode=310000', {headers:{'apikey': 'changguang'}}).then(response => {
                this.railList = response.body.metadata.infos
            })
        },
        wktToPolyline(val){
            let latlngs = []
            let tmp = val.replace('LINESTRING(','').replace(')','').split(',')
            tmp.forEach((item)=>{
                latlngs.push(item.split(' ').reverse())
            })
            return latlngs
        },
        exit() {
            // eslint-disable-next-line no-undef
            Exit.postMessage('')
        },
        closeBrowser() {
            this.browserShow = false
        },
        showTips() {
            // this.tipsShow = true;
            // setTimeout(()=>{
            //     this.tipsShow = false;
            // },5000)
        },
        toggle(val){
            if (!this.scannerShow){
                switch (val){
                    case 'mall':
                        if(this.mall == false){
                            if(this.pop == true){
                                this.currentPanel = ''
                                this.pop = false
                                this.popLayerShow = false
                            }
                            this.scannerShow = true
                            this.mall = true
                            setTimeout(()=>{this.mallLayerShow = true; this.scannerShow = false; this.showTips()},1200)
                        } else {
                            this.currentPanel = ''
                            this.mall = false
                            this.mallLayerShow = false
                        }
                        break
                    case 'pop':
                        if(this.pop == false){
                            if(this.mall == true){
                                this.currentPanel = ''
                                this.mall = false
                                this.mallLayerShow = false
                            }
                            this.scannerShow = true
                            this.pop = true
                            setTimeout(()=>{this.popLayerShow = true; this.scannerShow = false},1200)
                        } else {
                            this.currentPanel = ''
                            this.pop = false
                            this.popLayerShow = false
                        }
                        break
                    case 'rail':
                        if(this.rail == false){
                            this.scannerShow = true
                            this.rail = true
                            setTimeout(()=>{this.railLayerShow = true; this.scannerShow = false; this.showTips()},1200)
                        } else {
                            this.currentPanel = ''
                            this.rail = false
                            this.railLayerShow = false
                        }
                        break
                }
            }
        }
    },
    computed:{
        railStyleList(){
            let tmp = []
            let colorPallette = ['#f44336','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196f3','#03a9f4','#00bcd4','#009688','#4caf50','#8bc34a','#cddc39','#ffeb3b','#ffc107','#ff9800','#ff5722','#795548','#9e9e9e','#607d8b','#d32f2f','#c2185b','#7b1fa2','#512da8','#303f9f','#1976d2','#0288d1']
            for(let i=0; i<30; i++){
                tmp.push(
                    {
                        color: colorPallette[i],
                        weight: 5,
                    }
                )
            }
            return tmp
        },
    },
    watch:{
        browserShow(val){
            // eslint-disable-next-line no-undef
            IsBrowser.postMessage(val)
        },
        mall(){
            if(window["Yijing_status"]){
                window["Yijing_status"].postMessage("mall");
            }
        },
        pop() {
            if(window["Yijing_status"]){
                window["Yijing_status"].postMessage("pop");
            }
        },
        rail() {
            if(window["Yijing_status"]){
                window["Yijing_status"].postMessage("rail");
            }
        }

    },
    mounted() {
        window.yijing = this;

        this.getMallList()
        this.getRailList()
        if(localStorage.getItem('Yijing')==null){
            this.redPoint = true
        }
        this.share = this.$route.query.share
        if(this.share == 1){
            setTimeout(()=>{this.toggle('mall')},1000)
        }
        this.$http.get('/earthApi/yijing/getGeoRange?infoId=202112021857000583540').then(response => {
            if(response.data.status === 200){
                this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(response.body.data.maxgeo)).getBounds())
                this.$refs.map.mapObject.setMinZoom(response.body.data.maxZoom)
                this.mapShow = true
            } else {
                this.$router.push(
                    {
                        path: '/NotExist',
                    }
                )
            }
        })
    }
}
</script>

<style scoped lang="scss">

#tips {
    width: 232px;
    height: 32px;
    background: rgba(255,255,255,0.80);
    border-radius: 6px;
    line-height: 32px;
    font-size: 12px;
    text-align: center;
    color: #393939;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 100px;
}

#yijing {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.opacitySlide {
    position: absolute;
    z-index: 2;
    left: 24px;
    right: 24px;
    bottom: 160px;
    input[type=range] {
        width: 100%;
        background: white;
        height: 5px;
        border-radius: 3px;
        outline: none;
    }

}

#scanner{
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: -152px;
    height: 150px;
    background: linear-gradient(180deg,rgba(0,0,0,0.00), #99c0e0);
    border-bottom: #5aa6e4 solid 2px;
}

.browser{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 99;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    #top{
        width: 100%;
        height: 100px;
        background: rgba(246,246,246,1);
        font-size: 16px;
        font-weight: 500;
        color: black;
        text-align: left;
        position: relative;
    }
    #main{
        width: 100%;
        height: 100%;
    }
}

.panel{
    z-index: 9;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 400px;
    background: white;
    border-radius: 16px 16px 0px 0px;
    color: black;
    font-size: 12px;
    font-weight: 500;
    .divider{
        width: 100%;
        height: 2px;
        background-color: #d8d8d8;
    }
    .close{
        position: absolute;
        font-size: 14px;
        top: 13px;
        right: 13px;
    }
    .content{
        position: relative;
        margin: 24px;
        .maintitle{
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            margin-bottom: 12px;
        }
        .subtitle{
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 4px;
        }
        .legendChip{
            float: left;
            width: 96px;
            padding: 4px;
            .legend{
                display: inline-block;
                width: 16px;
                height: 16px;
                background: #fed79e;
                border: 1px solid #b8b8b8;
                margin-right: 8px;
            }
            .text{
                display: inline-block;
                font-size: 10px;
                line-height: 18px;
                transform: translateY(-4px);
            }
        }
        .attribution{
            position: absolute;
            bottom: 8px;
            right: 15px;
            font-size: 9px;
            color: #638eff;
        }

    }
}

.moreInfoPanel{
    z-index: 8;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 145px;
    background: rgba(0,0,0,0.60);
    border-radius: 16px 16px 0px 0px;
    color: white;
    font-size: 12px;
    font-weight: 500;
}

.cardContainer{
    overflow: scroll;
    display: flex;
    justify-content: left;
    .card{
        width: 120px;
        height: 90px;
        text-align: center;
        color: white;
        font-size: 10px;
        margin: 12px;
        margin-top: 40px;
        img{
            width: 120px;
            height: 75px;
            border-radius: 15px;
        }
    }
}

#bottomBar{
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 110px;
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 2px 4px 0px #000000;
    display: flex;
    justify-content: space-around;
    .button{
        margin-top: 24px;
        text-align: center;
        width: 48px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        line-height: 17px;
    }
}

.backButton{
    z-index: 10;
    height:48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top:40px;
}

.title{
    position: absolute;
    left: 0;
    right: 0;
    top:54px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: white;
}

#logo{
    position: absolute;
    right: 8px;
    top:54px;
    img{
        height: 24px;
    }
}


</style>
