<template>
    <div style="width: 100%; height: 100%; overflow: auto; -webkit-overflow-scrolling: touch;background-color: black">
        <img :src="detail.picture" width="100%"/>
       <!-- <ShareButton v-if="share == 1" style="position: fixed"/>-->
        <TopShareBar v-if="share ==1" />
        <vue-danmaku ref="danmaku" :danmus="detail.commentList" useSlot loop :speeds="50" :channels="5" randomChannel :debounce="2000">
            <div></div>
            <template slot="dm" slot-scope="{ index, danmu }">
                <div class="danmu">
                    <div class="tag">
                        {{ danmu.commentContent }}
                    </div>
                    <div class="icon">
                        <img :src="danmu.avatar"/>
                    </div>
                </div>
            </template>
        </vue-danmaku>
    </div>
</template>

<script>

import vueDanmaku from 'vue-danmaku'
import TopShareBar from '@/components/TopShareBar';
export default {
    name: "Event",
    components: {
        TopShareBar,
        vueDanmaku
    },
    data(){
        return{
            detail:{
                commentList: [
                ],
                outLink: "",
                picture: "",
                activityType: 1,
                activityId: 'ac202201121022000441643',
                share: 0
            },
        }
    },
    methods: {
        load() {
            this.$http.get('/earthApi/activity-info/getActivity').then(response => {
                this.detail = response.body.data;
                console.log(this.detail.activityType + ',' + this.detail.outLink + ',' + this.activityId);
                // eslint-disable-next-line no-undef
                EventInfo.postMessage(this.detail.activityType + ',' + this.detail.outLink + ',' + this.detail.activityId)
            })
        },

        sendDanmaku(val){
            this.$refs.danmaku.add(JSON.parse(val))
        }
    },
    mounted() {
        window.detail = this;
        this.infoId = this.$route.query.infoId
        this.share = this.$route.query.share
        this.load();
    }
}
</script>

<style scoped lang="scss">

.vue-danmaku{
    height: calc(100vh - 100px);
    position: fixed;
    top: 100px;
    width: 100%;
    pointer-events: none;
}

.danmu{
    margin-top: 60px;
    width: unset;
    .icon {
        width: 32px;
        height: 32px;
        border: 2px solid white;
        border-radius: 50%;
        box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.25);
        margin-top:-30px;
        background-color: white;
        img {
            margin-top: 1px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            object-fit:cover;
        }
    }
    .tag {
        color: rgba(74,74,74,1);
        font-size: 10px;
        line-height:22px;
        font-weight: 400;
        padding-left: 20px;
        padding-right: 8px;
        text-align: left;
        margin-left:16px;
        height: 22px;
        opacity: 1;
        background: #f6f6f6;
        border: 2px solid white;
        border-radius: 13px;
        box-shadow: 0px 2px 4px 0px rgba(211,211,211,0.50);
    }
}

</style>
