<template>
    <div class="routeSharePage">

        <l-map ref="routeSharePageMap" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
            <!--<l-tile-layer ref="mapLayer" :url="mapUrl" :options="mapSubdomains" />-->
            <l-tile-layer ref="mapLayer" :url="chinaOneUrl" :options="{tms:true}" />
        </l-map>

        <!--拍摄点信息栏-->
        <div :style="{'display':this.markerObjectList.length === 0 ? 'none' : 'block'}" class="markInfoBar">
<!--            &lt;!&ndash;拍摄点信息卡&ndash;&gt;-->
<!--            <div style="width:100%;height:105px;margin-top:21px;padding-left:32px;white-space: nowrap;">-->
<!--                <div v-for="(item,index) in markerObjectList" :key="index" class="markInfoBar-item">-->
<!--                    <img :src="item.markerInfo.thumb" class="markInfoBar-item-image">-->
<!--                    <div class="markInfoBar-item-info">-->
<!--                        <p class="markInfoBar-item-title">{{item.markerInfo.title}}</p>-->
<!--                        <p class="markInfoBar-item-poi">-->
<!--                            <span style="color:#675CB5;" class="iconfontFamily">&#xe61e;</span>-->
<!--                            {{item.markerInfo.poi}}-->
<!--                        </p>-->
<!--                        <p :style="describeHeightStyle" class="markInfoBar-item-describe">{{item.markerInfo.describe}}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            &lt;!&ndash;指示条&ndash;&gt;-->
<!--            <div style="width: calc(100% - 198px);height:4px;margin:18px 99px 0 99px;display:flex;">-->
<!--                <div v-for="(item,index) in markerObjectList" :key="'pagination'+index"-->
<!--                     :style="{'background':index===0 ? '#7f7f7f' : '#d8d8d8'}"-->
<!--                     style="height:100%;margin-left:2px;margin-right:2px;flex:1;"></div>-->
<!--            </div>-->

          <!--拍摄点信息卡-->
          <div style="width:100%;height:105px;margin-top:21px;padding-left:32px;white-space: nowrap;">
            <swiper ref="mSwiper" @slideChange="onPageChange">
              <swiper-slide v-for="(item,index) in markerObjectList" :key="item.markerId" >
                <div :key="index" class="markInfoBar-item" @click="intoRouteMarkerPointDetail(item.markerId)">
                  <img :src="item.markerInfo.thumb" class="markInfoBar-item-image">
                  <div class="markInfoBar-item-info">
                    <p class="markInfoBar-item-title">{{item.markerInfo.title}}</p>
                    <p class="markInfoBar-item-poi">
                      <span style="color:#675CB5;" class="iconfontFamily">&#xe61e;</span>
                      {{item.markerInfo.poi}}
                    </p>
                    <p :style="describeHeightStyle" class="markInfoBar-item-describe">{{item.markerInfo.describe}}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <!--指示条-->
          <div style="width: calc(100% - 64px);height:4px;margin:18px 32px 0 32px;display:flex">
            <div v-for="(item,index) in markerObjectList" :key="index" :style="{'background':index===swiperIndex ? '#7f7f7f' : '#d8d8d8'}"
                 style="height:100%;margin-left:2px;margin-right:2px;flex:1;"></div>
          </div>


        </div>

        <!--分享按钮-->
        <TopShareBar />
    </div>



</template>

<script>

// 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
import L from 'leaflet'
import { LMap, LTileLayer } from 'vue2-leaflet';
//引入标记点 拍摄点
import Vue from 'vue';
import RouteMark from './RouteMark';
const RouteMarkExtend = Vue.extend(RouteMark);

import TopShareBar from '@/components/TopShareBar';

import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name:"RouteSharePage",
    components: {LMap, LTileLayer,TopShareBar,swiper, swiperSlide,},
    data(){
        return{
            zoom: 12,
            center: L.latLng(40.074, 116.604),
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
            // mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
            chinaOneUrl:'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
            mapSubdomains:{subdomains: ['0', '1', '2', '3']},

            routeId:"",
            routeTitle:"",
            markerObjectList:[],   //拍摄点创建的L.marker对象及markerId

            describeHeightStyle:{},


          swiperIndex:0,


        }
    },
    methods:{

        //获取路线详情
        getRouteDetail(){
            this.$http.get("/earthApi/bestRoutePlan/getRoutePlan?mainInfoId=" + this.routeId).then(response => {
                console.log(response.data)
                if(response.data.status === 200){

                    //设置底图展示限制
                    this.$refs.routeSharePageMap.mapObject.setMaxBounds(L.geoJSON(JSON.parse(response.body.data.bestRoutePlan.maxgeo)).getBounds())
                    this.$refs.routeSharePageMap.mapObject.setMinZoom(response.body.data.bestRoutePlan.maxZoom + 1)

                    this.routeTitle = response.body.data.bestRoutePlan.routePlanTitle;
                    document.title = this.routeTitle;
                    //绘制线
                    let routeColor = response.body.data.bestRoutePlan.routeColor;
                    let routeJsonLine = JSON.parse(JSON.parse(response.body.data.bestRoutePlan.routePlan).jsonLine)
                    for(let i=0;i<routeJsonLine.features.length;i++){
                        let latlngs = [
                            [routeJsonLine.features[i].geometry.coordinates[0][1],routeJsonLine.features[i].geometry.coordinates[0][0]],
                            [routeJsonLine.features[i].geometry.coordinates[1][1],routeJsonLine.features[i].geometry.coordinates[1][0]]
                        ];
                        L.polyline(latlngs,{color:'#'+routeColor.substr(2).slice(2) + routeColor.substr(2).slice(0,2),weight:5}).addTo(this.$refs.routeSharePageMap.mapObject);
                    }

                    //标记点
                    let routeMarkerList = response.body.data.properties;
                    for(let i=0;i<routeMarkerList.length;i++){
                        let markerInfo = routeMarkerList[i];
                        //绘制 标记点、拍摄点
                        let icon = L.divIcon({
                            className: 'custom-div-icon',
                            html: new RouteMarkExtend({
                                propsData: {
                                    markerInfo: markerInfo,
                                },
                                methods: {
                                  //点击拍摄点事件
                                  clickRouteMarkerPoint() {




                                  },
                                },
                            }).$mount().$el,
                            iconSize: markerInfo.properties.markType === '标记点' ? [11,11] : [28, 28],
                            iconAnchor: markerInfo.properties.markType === '标记点' ? [5.5,5.5] : [15, 9]
                        });
                        let marker = L.marker(
                            [JSON.parse(markerInfo.geometry).coordinates[1],JSON.parse(markerInfo.geometry).coordinates[0]],
                            {icon:icon}
                        ).addTo(this.$refs.routeSharePageMap.mapObject);
                        if(markerInfo.properties.markType === '拍摄点'){
                            //存入拍摄点对象列表
                            this.markerObjectList.push({
                                markerObject:marker,
                                markerId:markerInfo.properties.id,
                                lon:markerInfo.properties.lon,
                                lat:markerInfo.properties.lat,
                                markerInfo:{
                                    thumb:markerInfo.properties.markThumb,
                                    title:markerInfo.properties.markName,
                                    poi:markerInfo.properties.poi,
                                    describe:markerInfo.properties.describe,
                                }
                            });
                            //拍摄点气泡
                            marker.bindPopup(
                                '<div class="routeMarkerPoint-bubble" onclick="intoRouteMarkerPointDetail(\''+ markerInfo.properties.id +'\')"><img src="' + markerInfo.properties.markThumb + '"/></div>',
                                { className: 'routeMarkerPointPopup',closeButton:false}
                            )
                        }
                    }

                    //显示第一个拍摄点的气泡
                    if(this.markerObjectList.length !== 0){
                        this.markerObjectList[0].markerObject.openPopup();
                        //地图飞到第一个拍摄点位置
                        this.$refs.routeSharePageMap.mapObject.flyTo(
                            L.latLng(this.markerObjectList[0].lat, this.markerObjectList[0].lon),response.body.data.bestRoutePlan.zoom,
                            {duration:2}
                        )
                      //底栏信息的高度
                      setTimeout(()=>{
                        let describeHeight = document.querySelectorAll(".markInfoBar-item-info")[0].offsetHeight - document.querySelectorAll(".markInfoBar-item-title")[0].offsetHeight - document.querySelectorAll(".markInfoBar-item-poi")[0].offsetHeight;
                        if(describeHeight > 31){
                          this.describeHeightStyle = {'-webkit-line-clamp': '2'}
                        }
                        else{
                          this.describeHeightStyle = {'-webkit-line-clamp': '1'}
                        }
                      },10)
                    }
                    else{
                        //地图飞到地图中心点位置
                        this.$refs.routeSharePageMap.mapObject.flyTo(
                            L.latLng(response.body.data.bestRoutePlan.lat, response.body.data.bestRoutePlan.lon),response.body.data.bestRoutePlan.zoom,
                            {duration:2}
                        )
                    }

                }
                else if(response.data.status === 305 || response.data.status === 404){
                  this.$router.push(
                      {
                        path: '/NotExist',
                      }
                  )
                }
                else{
                    console.log(response.data.status + response.data.message)
                }
            })
        },

      //滑动卡片
      onPageChange(){
        this.swiperIndex = this.$refs.mSwiper.swiper.activeIndex
        this.markerObjectList.find((item,index)=>{
          if(index ==this.swiperIndex){
            this.markerObjectList[index].markerObject.openPopup();
            this.$refs.routeSharePageMap.mapObject.flyTo(L.latLng(item.lat,item.lon),parseInt(this.$refs.routeSharePageMap.mapObject.getZoom()))
          }
        })

      },
      //点击卡片或气泡进入拍摄点详情
      intoRouteMarkerPointDetail(markId){

          // //弹出气泡（防止气泡当时没展示）
          // this.markerObjectList.find((item,index)=>{
          //   if(item.markerId == markId){
          //     this.markerObjectList[index].markerObject.openPopup();
          //     this.$refs.routeSharePageMap.mapObject.flyTo(L.latLng(item.lat,item.lon),parseInt(this.$refs.routeSharePageMap.mapObject.getZoom()))
          //   }
          // })

        //跳转拍摄点详情页面
        this.$router.push(
            {
              path: '/PhotoLocation/MarkDetailPage',
              query:{
                markId:markId
              }
            }
        )
      }



    },

    mounted(){
        if(this.$route.query.infoId){
          this.routeId = this.$route.query.infoId;
          this.getRouteDetail();
          window.intoRouteMarkerPointDetail = this.intoRouteMarkerPointDetail //将vue方法挂载到window对象
        }
        else{
          window.location.href = this.$route.fullPath.replace("routeId","infoId")
        }

    }



}

</script>

<style scoped>

    .routeSharePage {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .routeSharePage p{
        margin:0;
    }


    .markInfoBar{
        position:absolute;
        bottom:0;
        width:100%;
        height:180px;
        background:#ffffff;
        border-radius:12px 12px 0 0;
        z-index:999;
        overflow-x: hidden;
    }
    .markInfoBar-item{
        width:calc(100% - 64px);
        height:105px;
        border-radius:8px;
        box-shadow:0 0 6px 0 rgba(164,164,164,0.25);
        margin-right:15px;
        display:inline-block;
        white-space: normal;
    }
    .markInfoBar-item-image{
        width:100px;
        height:75px;
        border-radius:12px;
        margin:15px 5px 0 9px;
        float:left;
    }
    .markInfoBar-item-info{
      width: calc(100% - 119px);
      height:calc(100% - 32px);
      margin-top:15px;
      float:left;
    }
    .markInfoBar-item-title{
        color:#4A4A4A;
        font-size:14px;
    }
    .markInfoBar-item-poi{
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:10px;
    }
    .markInfoBar-item-describe{
        /*height:calc(100% - 41px);*/
        margin-top:3px !important;
        color:#4A4A4A;
        font-size:12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }

</style>
