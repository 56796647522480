<template>
  <div class="videoSharePage">
    <!--顶部下载栏-->
    <TopShareBar />
    <!--内容-->
    <div style="height:100%;background:#000000;">
      <video v-if="videoSrc !== ''" controls="controls" width="100%" height="100%" autoplay :poster="videoThumbSrc">
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <!--介绍-->
    <div v-show="screenOrientation === 'horizontal'" class="videoSharePage_context">
      <p>{{videoContext}}</p>
    </div>
  </div>

</template>

<script>
import TopShareBar from '@/components/TopShareBar';
export default {
  name: "VideoSharePage",
  components: {TopShareBar},
  data(){
    return{

      mainInfoId:"",

      videoTitle:"",
      videoContext:"",
      videoSrc:"",
      videoThumbSrc:"",

      screenOrientation: "horizontal",

    }

  },

  methods:{

    //获取详情
    getDetail(){
      this.$http.get('/earthApi/main-info/getMainInfoDetail?shareType=1&infoId='+this.mainInfoId).then(response => {
        console.log(response.body)
        if(response.body.status === 200){
          this.videoTitle = response.body.data.mainTitle;
          this.videoContext = response.body.data.context;
          this.videoSrc = response.body.data.picture1;
          this.videoThumbSrc = response.body.data.thumbPicture.replace("_thumb","");

          document.title = this.videoTitle;
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },


    //监听横竖屏变化
    renderResize(){
      this.screenOrientation = window.screen.orientation;
      if(this.screenOrientation.angle === 0 || this.screenOrientation.angle === 180){
        //横屏
        this.screenOrientation = "horizontal"
      }
      else{
        //竖屏
        this.screenOrientation = "vertical"
      }


    }
  },

  mounted(){
    if(this.$route.query.infoId){
      this.mainInfoId = this.$route.query.infoId;
      this.getDetail()
      window.addEventListener("resize", this.renderResize, false)
    }
    else{
      window.location.href = this.$route.fullPath.replace("mainInfoId","infoId")
    }
  },
  beforeDestroy () {
    // 移除监听
    window.removeEventListener("resize", this.renderResize, false)
  }



}
</script>

<style scoped>
.videoSharePage {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
.videoSharePage_context{
  position:absolute;
  bottom:75px;
  left:0;
  right:0;
  margin:0;
  padding:0 15px;
  font-size:14px;
  color:#ffffff;

}
</style>

<style>
.videoSharePage p{
  margin:0;
}
</style>