<template>
    <img style="width: 100%; margin-bottom: -4px" src="../assets/app.jpg" />
</template>

<script>
export default {
    name: "App"
}
</script>

<style scoped>

</style>
