<template>
    <!--页面调用参数：topLayerCite，bottomLayerCite，areaGeo，share默认为0，compareFullScreen默认全屏，titleShow默认展示，title默认卷帘对比-->
    <div id="compareHorizontal" :style="style">
        <l-map ref="compareHorizontalMap" style="z-index: 0" :options="option" :maxBounds="maxBounds"></l-map>

        <div v-if="share == 1" class="bottomBar">
            <div id="openApp">共生地球APP内打开</div>
        </div>
        <div v-if="titleShow" id="mainTitle">
            <img @click="$router.go(-1)" height="14" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABHklEQVRIS7XWK05EQRCF4fOvBwxhBSxgJASBQCAQiBEIBAkzAoFAIBAIBAJBsLOAWQACASvB4Jp0Agm5uV3V1dO3F3C+flWlUKeVUrqSNJP0JWkJrMei6eH9Yot/WWtgbxJwBMvOO7DTHSxg2ZkBq66ggR0Ar6WnanrDVixvIgxugoXBTbEQ2AOrBnthVWBPzAV7YyY4BeaBH5K2BgVsFnVNXy7WYUrpTdLuIOQQeKkJDnca40qPgOdW1Ow0BnoMPLWgbmsz0BPgMYq6YA400FPgIYJWgQ56BtzXotWgg86Buxo0BDroOXDroWHQQS+AGwttAh30ErgOF753NQ66AJZdh6i/sELJfALbk4CFk043CA9Oui/pW1L+saOj/g9hx44d+08TfwAAAABJRU5ErkJggg==" />
            <span>{{$route.query.title ? $route.query.title : '卷帘对比'}}</span>
        </div>
    </div>
</template>

<script>

    import L from "leaflet";
    import {LMap} from 'vue2-leaflet';
    import '@/lib/side-by-side/leaflet-side-by-side'
    // import 'leaflet-side-by-side'
    export default {
        name:"CompareHorizontal",
        components: {
            LMap,
        },
        data() {
            return {
                zoom: 14,
                center: L.latLng(44.0, 125.4),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas(), maxZoom: 18, minZoom: 5},
                areaGeo:"",
                topLayer:{},
                bottomLayer:{},

                share: 0,

                //展示方式：全屏（默认）,文章内部
                compareFullScreen:true,
                titleShow:true

            }
        },
        methods: {
            addLayer(){
                let topLayer = L.tileLayer('https://map.charmingglobe.com/tile/dataset/' + this.topLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',{tms:true}).addTo(this.$refs.compareHorizontalMap.mapObject);
                let bottomLayer = L.tileLayer('https://map.charmingglobe.com/tile/dataset/' + this.bottomLayer.cite + '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf',{tms:true}).addTo(this.$refs.compareHorizontalMap.mapObject)
                //创建卷帘 水平卷帘需要传参数
                L.control.sideBySide(topLayer, bottomLayer,{compareType:"horizontal"}).addTo(this.$refs.compareHorizontalMap.mapObject);
                setTimeout(()=>{
                    this.$refs.compareHorizontalMap.mapObject.fitBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
                },1000)

            },

            //水平卷帘修改样式，默认是垂直的
            setStyle(){
                document.querySelector(".leaflet-sbs-range").setAttribute("style","height: 0;top: 50%;left:0;width: 100%;");
                document.querySelector(".leaflet-sbs-divider").setAttribute("style","top: 0;left:50%;bottom: 0;margin-left: -2px;width: 4px;height: 100%;");
            }


        },
        mounted() {
            this.topLayer = {
                cite: this.$route.query.topLayerCite
            }
            this.bottomLayer = {
                cite: this.$route.query.bottomLayerCite
            }
            this.areaGeo = decodeURI(this.$route.query.areaGeo)
            // this.$refs.compareHorizontalMap.mapObject.setMaxBounds(L.geoJSON(JSON.parse(this.areaGeo)).getBounds())
            this.share = this.$route.query.share
            this.addLayer()

            //展示方式
            if(this.$route.query.compareFullScreen){
                this.compareFullScreen = false;
            }
            if(this.$route.query.titleShow){
                this.titleShow = false;
            }

            this.setStyle();


            // if(this.compareFullScreen){
            //     let translateNum = (window.innerHeight - window.innerWidth) / 2
            //     document.getElementById("compareHorizontal").setAttribute("style","width:100vh;height:100vw;transform:rotate(90deg) translateX(" + translateNum +"px) translateY( " + translateNum + "px)")
            // }

        },
        computed:{
            style() {
                if(this.compareFullScreen){
                    return "width:100%;height: calc(100vh);";
                }
                else{
                    return "width:100%;height: calc(100vw / 2);";
                }

            }
        }

    }

</script>

<style scoped lang="scss">

#compareHorizontal{
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}
#compareHorizontal p{
    margin:0;
}


#mainTitle{
    img{
        position: absolute;
        left: 24px;
        top:54px;
        z-index: 10;
    }
    span{
        position: absolute;
        left: 48px;
        top:50px;
        z-index: 10;
        color: white;
    }
    }


    .bottomBar{
        width:100%;
        height: 64px;
        background: rgba(0,0,0,0);
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 9999;
    #openApp{
        color: white;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        margin: 16px 24px;
        height: 32px;
        background: #675cb8;
        border-radius: 18px;
    }
}


</style>
