<template>
    <div class="nameClass">
        <div style="background-color: #f4f4f4;padding:22px 14px 22px 14px;overflow-y: scroll;width: 100%" v-html="nameHtml"></div>
    </div>
</template>

<script>
    export default {
        name: "Nameing",
        data(){
            return{
                nameHtml:'',
                htmlId:'',
            }
        },
        methods:{
            getNameing(){
                this.$http.get('/earthApi/satelliteInfo/getAliasInfo?satelliteId='+this.htmlId).then(response => {
                    if(response.body.status == 200){
                        this.nameHtml = response.body.data
                    }else{
                        this.$router.push(
                            {
                                path: '/NotExist',
                            }
                        )
                    }
                })
            }
        },
        mounted() {
            window.leaflet = this
            this.htmlId = this.$route.query.htmlId
            this.getNameing()
        }

    }
</script>

<style lang="scss"  scoped>

    .nameClass{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        //overflow: hidden;
    }

</style>
