<template>
  <div class="articleSharePage">
    <!--顶部下载栏-->
    <TopShareBar />
    <!--内容-->
    <div style="padding:65px 20px 20px 20px;">

      <div class="articleSharePage_title">{{articleTitle}}</div>

      <div v-html="articleHtmlData"></div>

    </div>
  </div>

</template>

<script>
import TopShareBar from '@/components/TopShareBar';
export default {
  name: "ArticleSharePage",
  components: {TopShareBar},
  data(){
    return{

      mainInfoId:"",

      articleTitle:"",
      articleHtmlData:"",

    }


  },
  methods:{

    //获取详情
    getDetail(){
      this.$http.get('/earthApi/main-info/getMainInfoDetail?shareType=1&infoId='+this.mainInfoId).then(response => {
        if(response.body.status === 200){
          console.log(response.body.data)
          this.articleTitle = response.body.data.mainTitle;
          this.articleHtmlData = response.body.data.context;

          document.title = this.articleTitle;
        }
        else if(response.body.status === 404){
          this.$router.push(
              {
                path: '/NotExist',
              }
          )
        }
        else{
          console.log(response.data.status + response.data.message)
        }
      })
    },

  },
  mounted(){
    if(this.$route.query.infoId){
      this.mainInfoId = this.$route.query.infoId;
      this.getDetail();
    }
    else{
      window.location.href = this.$route.fullPath.replace("mainInfoId","infoId")
    }
  }


}
</script>

<style scoped>
.articleSharePage {
  font-family: 微软雅黑;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: auto;
}
.articleSharePage_title{
  margin-bottom:20px;
  font-size:18px;
  color:#4a4a4a;
  font-weight:bold;
}

</style>

<style>
.articleSharePage p{
  margin:0;
}
</style>