<template>
    <!--弹窗类资讯 1图文文章 7tips 18联想文章-->
<!--    id="alumniArticle"-->
    <div id="alumniArticle"  style="overflow:hidden;">
        <!--加载中-->
        <div v-if="loading">
            <div class="loadingArea" style="width:100%;height:25px;margin-bottom:15px;">
                <div class="loadingArea_white"></div>
            </div>
            <div style="margin-bottom:5px;overflow:hidden;">
                <div class="loadingArea" style="width:32px;height:32px;border-radius:50%;float:left;">
                    <div class="loadingArea_white"></div>
                </div>
                <div class="loadingArea" style="width:100px;height:24px;margin-top:4px;margin-left:6px;float:left;">
                    <div class="loadingArea_white"></div>
                </div>
            </div>
            <div class="loadingArea" style="width:100%;height:25px;margin-bottom:5px;">
                <div class="loadingArea_white"></div>
            </div>
            <div class="loadingArea" style="width:100%;height:300px;">
                <div class="loadingArea_white"></div>
            </div>
        </div>

        <div v-if="!loading">
            <!--校友会信息-->
            <div style="height: 25px;margin-top: 20px;width: 100%;">
                <img style="float: left;
        width: 35%;height: 22px"  src="../../assets/alumni/branch2.png" alt="" id="branch2"/>
                <div style="width:28%;height:27px;float: left;margin-left:1%;border-radius: 15px;background: -webkit-linear-gradient(bottom,#1D48D0,#82A4EE) no-repeat;font-size: 12px;color: white;text-align: center;line-height: 27px">{{articleDetail.mainTag}}</div>
                <img style="float: right;
        width: 35%;height: 22px"  src="../../assets/alumni/branch.png" alt="" />
            </div>

<!--            树枝-->
            <div style="width: 3px;height: 25px;background-color: #BF9072;position: absolute;right: 10%;top:35px"></div>
            <div style="margin-top: 15px;width: 100%;height:30px;">
                <p style="float: left;font-weight: bold;font-size: 14px;color: black;margin: 0;line-height: 30px">{{articleDetail.mainInfoTitle}}</p>
                <div class="heat" >
                    <span class="iconfontFamily" style="float: left;color: #8b51ff;font-size:10px;margin-top: 10px;margin-left: 4px" >&#xe70c;</span>
                    <p style="margin: 0;font-weight: 400;font-size: 12px;color: #675CB5;text-align: center;line-height:30px" @click="openHeatMap">校友分布</p>
                </div>
            </div>
            <div  style="height: 14px;width: 100%;margin-top: 8px;margin-bottom: 18px">


                <!--用户名-->
                <div class="userLine">
                    <img :src="articleDetail.picture" class="circleAvatar">
                    <div style="height:100%;float:left;">
                        <p class="userName">{{articleDetail.nickName}}</p>
                        <p class="createTime">{{articleDetail.gmtCreateTime}}</p>
                    </div>
                </div>
            </div>

            <!--去认证/我已认证-->
            <div v-show="articleDetail.isAlumnus == 0&&(!this.share)" style="float: right;height: 14px;margin-top: 7px" @click="verify">
                <span class="iconfontFamily" style="float: left;color: #8b51ff;font-size:12px;margin-right: 2px" >&#xe70b;</span>
                <p style="color: #4A4A4A;font-size:12px;line-height: 14px;float: right;margin: 0;">去认证</p>
            </div>

            <div v-show="articleDetail.isAlumnus == 1" style="float: right;height: 14px" @click="cancelAlumniVerify">
                <span class="iconfontFamily" style="float: left;color: #8b51ff;font-size:12px;margin-right: 2px;" >&#xe70a;</span>
                <p style="color: #4A4A4A;font-size:12px;line-height: 12px;float: right;margin: 0">我已认证</p>
            </div>

            <div style="height: 19px;margin-bottom: 20px">
                <p v-show="articleDetail.alumnusNum === 0" style="margin: 0;float: left;color: #4A4A4A;font-size:10px;line-height: 14px">暂时还没有人认证呢！快去认证吧</p>
                <span v-show="articleDetail.alumnusNum === 0" class="iconfontFamily" style="float: left;color: grey;font-size: 8px;margin-left: 3px" >&#xe72f;</span>
                <img v-show="articleDetail.alumnusNum >= 1" :src="articleDetail.picture1" class="circleAvatar2">
                <img v-show="articleDetail.alumnusNum >= 2" :src="articleDetail.picture2" class="circleAvatar2">
                <img v-show="articleDetail.alumnusNum >= 3" :src="articleDetail.picture3" class="circleAvatar2">
                <p v-show="articleDetail.alumnusNum !== 0" style="float: left;color: #4A4A4A;font-size:10px;line-height: 10px;margin-left:15px;">等{{articleDetail.alumnusNum}}人已认证</p>
            </div>
            <!--地址-->
            <div  class="addressLine">
                <p style="line-height:25px;">
                    <span class="addressIcon iconfontFamily">&#xe61e;</span>
                    {{articleDetail.geoName}}
                </p>
            </div>
            <!--图文文章正文-->
            <div class="contextHtmlLine"  v-html="articleDetail.context"></div>
        </div>

        <div v-show="this.$store.getters.alumniHeatMapShare" style="position: fixed;bottom:10%;left:10%;border-radius:16px;width: 80%;height: 32px;background-color: #8375EE;text-align: center;line-height: 32px;color: white;font-size: 14px">立即下载 共生地球APP</div>
    </div>
</template>

<script>
    export default {
        name:"AlumniArticle",
        data(){
            return{
                avatarMargin:0,
                infoId:"202111241050000481199",
                token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjE4NzQ0MDIzMDAxIiwiZXhwIjoxNjM4NTA0NjIyLCJ1c2VySWQiOjEyMzQ1NiwiZW1haWwiOiIifQ.LXTU1ALdORIqNB3vFsgVq37vls_7xD8OL4XHgwHaTrQ",
                imei:"2669df49-1e60-4f98-8b50-768537fe5d98",
                loading:true,
                verified:false,
                articleDetail:{},
                //联想文章 当前展示的是系列中第几项（从1开始）
                seriesCurrIndex:1,
                userId:"",
                lng:"",
                lat:""
            }
        },
        computed:{

            toDoubleDigit(){
                return (num)=>{
                    if(num >= 10){
                        return num;
                    }
                    else{
                        return "0"+num;
                    }
                }
            },
            seriesCurrThumbStyle(){
                return (sequences)=>{
                    if(sequences === this.seriesCurrIndex){
                        return {
                            'width':'50px',
                            'height':'50px',
                            'margin-top':'-4px',
                        }
                    }
                    else{
                        return{
                            'border':'1px solid #FFDA96'
                        }
                    }
                }


            }
        },
        methods:{
            cancelAlumniVerify(){
                if(window["cancelAlumniVerify"]){
                    window["cancelAlumniVerify"].postMessage("cancelAlumniVerify");
                }
            },
            //加载内容
            createPageContent(){
                this.$http.get('/earthApi/alumnus/getAlumnusInfo?userId='+this.userId+'&infoId='+this.infoId, {headers:{'Authorization': 'Bearer ' + this.token,'imei':this.imei}}).then(response => {
                    this.loading = false;
                    this.articleDetail = response.body.data;
                    if(this.articleDetail.isAlumnus === "1"){
                        this.$store.commit('isAlumni',true)
                    }else{
                        this.$store.commit('isAlumni',false)
                    }
                    // console.log("----------------------")
                    // console.log(this.$store.getters.isAlumni)
                    // // console.log(JSON.stringify(response.body.data))
                    // console.log(response.body.data.lng)
                    // if(response.body.data.lat!== undefined &&response.body.data.lat!== null){
                    //     this.lng = response.body.data.lng;
                    //     this.lat = response.body.data.lat;
                    //     this.$store.commit('alumniVerifyLat',response.body.data.lat)
                    //     this.$store.commit('alumniVerifyLng',response.body.data.lng)
                    //     console.log(response.body.data.lat)
                    //     console.log(response.body.data.lng)
                    // }

                    this.$store.commit('alumniMainTag', this.articleDetail.mainTag)
                    this.avatarMargin = response.body.data.alumnusNum * 15
                });
                //获取页面高度传给flutter
                setTimeout(()=>{
                    //浏览器高度传送给flutter
                    if(window["AlumniWebViewHeight"]){
                        window["AlumniWebViewHeight"].postMessage(document.getElementById('alumniArticle').clientHeight);
                    }
                },500)
            },
            openHeatMap(){
                if(this.$store.getters.alumniHeatMapShare){
                    this.$router.push(
                        {
                            path: '/AlumniHeatMap/AlumniHeatMap',
                            query: {
                                avatarUrl: "",
                                lat: 43,
                                lng: 125,
                            }
                        }
                    )
                }else{

                    if(window["OPENHEATMAP"]){
                        window["OPENHEATMAP"].postMessage("");
                    }

                }
            },
            verify(){
                if(this.share){
                    ///Toast提示
                }else{
                    if(window["OpenVerify"]){
                        window["OpenVerify"].postMessage("");
                    }
                }
            },

        },
        created(){
            window.intoCompareFullScreen = this.intoCompareFullScreen;//字符串中的方法 这样写就可以获取到
        },
        mounted(){
            window.alumniArticle= this;
            this.infoId = this.$route.query.infoId
            this.token = this.$route.query.token
            this.imei = this.$route.query.imei
            if(this.$route.query.share === "1"){
                this.$store.commit('alumniHeatMapShare',true)
            }else{
                this.$store.commit('alumniHeatMapShare',false)
            }
            this.createPageContent();
        }



    }

</script>

<style scoped>
    /*#alumniArticle{*/
    /*    font-family: 微软雅黑;*/
    /*    -webkit-font-smoothing: antialiased;*/
    /*    -moz-osx-font-smoothing: grayscale;*/
    /*    text-align: center;*/
    /*    color: #2c3e50;*/
    /*    position: absolute;*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*    z-index: 0;*/
    /*    overflow: hidden;*/
    /*}*/
    /*loading动画效果*/
    @-webkit-keyframes loading {
        0%{left:0;}
        100%{left:100%;}
    }
    @keyframes loading{
        0%{left:0;}
        100%{left:100%;}
    }
    .loadingArea{
        position:relative;
        background:#d8d8d8;
        border-radius:4px;
        overflow:hidden;
    }
    .loadingArea_white{
        position:absolute;
        top:0;
        left:50%;
        width:6px;
        height:100%;
        border-radius:4px;
        background: -webkit-linear-gradient(left, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* 标准的语法 */
        box-shadow: 0 0 20px #ffffff;
        animation:loading 1s infinite;
        -webkit-animation:loading 1s infinite;
    }



    .fullScreenBtn{
        position: absolute;
        right: 8px;
        top:8px;
        z-index: 10;
    }

    .titleLine{
        margin-bottom:15px;
        font-size:16px;
        color:#000000;
        font-weight:bold;
    }
    .userLine{
        width:100%;
        height:32px;
        margin-bottom:10px;
    }
    .circleAvatar{
        width:28px;
        height:28px;
        border-radius:50%;
        margin-right:3px;
        float:left;
    }
    .circleAvatar2{
        width:19px;
        height:19px;
        border-radius:50%;
        margin-right:-8px;
        border: 1px solid white;
        float:left;
        box-shadow: 2px 2px 2px #888888;
    }
    .userName{
        margin:1px 0 2px 0;
        font-size:10px;
        color:#000000;
        line-height: 14px;
    }
    .createTime{
        font-size:10px;
        color:#979797;
        margin: 0;
        line-height: 14px;

    }
    .focusBtn{
        /*width:45px;*/
        height:20px;
        border:1px solid #675CB5;
        border-radius:12px;
        margin:6px 0 0 29px;
        padding:0 10px;
        float:left;
        font-size:10px;
        color:#675CB5;
        text-align:center;
    }

    .addressLine{
        width:100%;
        height:25px;
        background:#F5F5F6;
        border-radius:6px;
        margin-bottom:10px;
        padding:0 10px;
        font-size:10px;
        color:#979797;
    }
    .addressIcon{
        -webkit-background-clip:text;
        -webkit-text-fill-color: transparent;
        background-image:-webkit-linear-gradient(top,#418BFF,#3A46E3);
    }

    .contextHtmlLine{
        font-size:14px;
        color:#000000;
    }

    .contextLine{
        margin-bottom:10px;
        font-size:14px;
        color:#000000;
    }
    .contextImage{
        width:calc((100vw - 20px) / 3);
        height:calc((100vw - 20px) / 3);
        margin-bottom:10px;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
        float:left;
    }
    .contextImageRecord{
        position:relative;
        width:100%;
        height:160px;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
    }
    .contextImageRecord_btn{
        position:absolute;
        bottom:6px;
        right:4px;
        font-size:12px;
        color:#ffffff;
    }


    .seriesList{
        width:100%;
        height:68px;
        margin-bottom:20px;
        white-space:nowrap;
        overflow-x:auto;
        overflow-y:hidden;
    }
    .seriesList::-webkit-scrollbar{
        display:none;
    }

    .seriesListItem{
        position:relative;
        height:calc(100% - 4px);
        margin-top:4px;
        display: inline-block;
    }
    .seriesListItem_thumb{
        position:relative;
        width:39px;
        height:39px;
        border-radius:50%;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
        float:left;
    }
    .seriesListItem_thumb p{
        width:100%;
        height:100%;
        border-radius:50%;
        background:rgba(117,137,251,0.7);
        font-size:14px;
        color:#ffffff;
        text-align:center;
        line-height:39px;
    }
    .seriesListItem_line{
        width:43px;
        height:1px;
        border-bottom:1px dashed #979797;
        margin-top:21px;
        float:left;

    }
    .seriesListItem_text{
        position:absolute;
        bottom:0;
        transform:translate(-50%);
        max-width:65px;
        line-height:13px;
        font-size:10px;
        color:#4a4a4a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; /*设置隐藏部分为省略号*/
    }

    /*背景图需放在css中设置，在html中写时路径需要使用require形式*/
    .heat{
        width: 72px;
        height: 30px;
        float: right;
        background-image: url(../../assets/alumni/purpleBackground.png);
    }

</style>
