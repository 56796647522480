<template>
  <div style="position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);text-align:center;">
<!--    <p class="iconfontFamily" style="color: #D8D8D8;font-size:50px;">&#xe6d2;</p>-->
    <img src="../assets/NotExist/notExist.png" style="width:52px;height:52px;margin:0;">
    <p style="margin-top:20px;margin-bottom:0;color:#6D7278;">该内容不存在</p>
  </div>
</template>

<script>
export default {
  name: "NotExist"
}
</script>

<style scoped>

</style>