<template>
    <div v-show="showDownloadApp" style="width: 100%;height: 55px;background-color: rgba(255,255,255,0.60);position: fixed;top: 0px;text-align: center;line-height: 55px;z-index: 999;">
        <div style="height: 35px;float: left;margin-left: 15px;margin-top: 11px">
            <img src="../assets/iconLogo.png" width="35" height="35"/>
        </div>
        <div style="height: 35px;width: 110px;float: left;margin-left: 6px;margin-top: -7px">
            <div style="height:18px;font-size: 14px;font-weight: 400;color: #000000;text-align: left">共生地球</div>
            <div style="height:13px;font-size: 10px;font-weight: 400;color: #000000;text-align: left">吉林一号探索世界</div>
        </div>
        <div class="shareButton"><a id="openApp" :href="shareUrl">App内打开</a></div>
    </div>
</template>

<script>
export default {
    name: "TopShareBar",
    data(){
        return {
            typeList:{
                "/LayerCompare": "16",
                "/Yijing": "11",
                "/Display3D": "14",
                "/PlaneRecognition": "6",
                "/Article/ArticleSharePage": "1",
                "/VideoSharePage":"3",
                "/PhotoLocation/RouteSharePage":"5",
                "/WallpaperSharePage":"2",
                "/Tips/TipsPage":"7",
                "/BubbleNews": "17",
                "/Drones": "20",
                "/FireAlarm": "12",
                "/QuickFlash/productMain": "101",
                "/Event": "Event"
            },

          showDownloadApp:false,

        }
    },
    computed:{
        shareUrl(){
            return '/OpenApp?infoId=' + this.$route.query.infoId + '&type=' + this.typeList[this.$route.path]
        }
    },
  mounted(){
    if(this.$route.query.downloadApp == 1){
      this.showDownloadApp = true;
    }
    else if(this.$route.query.downloadApp == 0){
      this.showDownloadApp = false;
    }

  }
}
</script>

<style lang="scss" scoped>
.shareButton{
    width: 75px;
    height: 28px;
    background: #695EC1;
    border-radius: 14px;
    float: right;
    margin-right: 15px;
    margin-top: 13px;
    #openApp{
        text-decoration: none;
        display: block;
        color: white;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        line-height: 28px;
        height: 28px;
        background: #695EC1;
        border-radius: 27px;
        box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
    }
}

</style>
