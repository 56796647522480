<template>
    <div id="heatMap">
        <div id="map">
        </div>
        <div v-show="this.$store.getters.alumniHeatMapShare" style="position: fixed;bottom:10%;left:10%;z-index: 2;border-radius:16px;width: 80%;height: 32px;background-color: #8375EE;text-align: center;line-height: 32px;color: white;font-size: 14px">立即下载 共生地球APP</div>
        <div v-show="this.$store.getters.alumniHeatMapShare" style="position: fixed;top:30px;left:20px;z-index: 2">
            <span class="iconfontFamily" style="float: left;color: black;font-size:20px;" @click="goBack">&#xe63c;</span>
        </div>
    </div>

</template>

<script>

    // 引入Leaflet对象 挂载到Vue上，便于全局使用，也可以单独页面中单独引用
    import L from "leaflet";
    import HeatmapOverlay from 'heatmap.js/plugins/leaflet-heatmap'
    // import html2canvas from "html2canvas";
    export default {
        name: "AlumniHeatMap",
        data(){
            return {
                map: "",
                avatarMarker: null,
                lat:43.88,
                lng:125.35,
                avatarUrl:"",
                htmlString:"",
                share: false,
                heatMapData:[],
            }
        },
        mounted() {
            window.alumniHeatMap = this;
            this.avatarUrl = this.$route.query.avatarUrl
            this.lat = this.$route.query.lat
            this.lng = this.$route.query.lng
            this.init();
            this.getHeatData();
        },
        methods:{
            init() {
                this.initMap();
                this.heatMapFlyTo(this.lat, this.lng);
                // this.initHeatMapLayer();
                //用html的div的样式来创建marker的icon，注意需要加className属性，否则会出现marker1的问题
                if(this.avatarUrl != ""){
                    this.initMarker();
                }
            },
            getHeatData(){
                // console.log(this.$store.getters.alumniMainTag)
                this.$http.post('/earthApi/alumnus/getAlumnusHotMap?mainTag='+this.$store.getters.alumniMainTag +'&userId=1629776305288221').then(response=> {
                    this.heatMapData = response.body.data.data
                    this.initHeatMapLayer()
                    // console.log("--------三大--------")
                    // console.log(this.heatMapData[0].lat)
                    // console.log(response.body.data.lat)
                    // console.log(response.body.data.lng)
                })
            },
            initMap(){
                const baseLayer = L.tileLayer(
                    "https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543",
                    {maxZoom: 10, minZoom: 3,subdomains: ['0', '1', '2', '3']}
                );
                const baseLayer2 = L.tileLayer(
                    "https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543",
                    {maxZoom: 10, minZoom: 3,subdomains: ['0', '1', '2', '3']}
                );
                this.map = L.map("map", {
                    center: [this.lat , this.lng],
                    minZoom: 3,
                    maxZoom: 10,// 地图中心
                    zoom: 5, //缩放比列
                    zoomControl: false, //禁用 + - 按钮
                    doubleClickZoom: false, // 禁用双击放大
                    attributionControl: false,// 移除右下角leaflet标识
                    crs: L.CRS.EPSG3857,
                    layers:[baseLayer,baseLayer2]
                });
            },
            initHeatMapLayer(){
                const heatData = {
                    max: 20000,
                    data:this.heatMapData
                    // data: [
                    //     {lat: 32.6408, lng:109.7728, count: 1},{lat:43.88, lng:125.35,count:60},{lat:33.88, lng:115.35,count:20},{lat:46.88, lng:125.35,count:40},{lat:40.88, lng:115.35,count:10},]
                };
                const cfg = {
                    "radius": 3,
                    "maxOpacity": .8,
                    "scaleRadius": true,
                    "useLocalExtrema": true,
                    latField: 'lat',
                    lngField: 'lng',
                    valueField: 'count'
                };
                const heatmapLayer = new HeatmapOverlay(cfg);
                heatmapLayer.addTo(this.map);
                heatmapLayer.setData(heatData);
            },
            initMarker(){
                var avatarIcon = L.divIcon({
                    //html: this.picture,
                    html:"<div><div style='width:54px;height:54px;border-radius:50%;background-color:wheat;border:3px solid #FFF3C54F'><img src="+this.avatarUrl+" style='width:54px;height:54px;border-radius:50%;'></div> <div style='width: 0;height: 0;border-left: 5px solid transparent;border-right: 5px solid transparent;border-top: 8px solid wheat;margin-left: 25px;margin-top: -2px'></div></div>",
                    className: 'ss',iconAnchor: [30, 63]},);
                this.avatarMarker =  L.marker([this.lat, this.lng], { icon: avatarIcon })
            },
            heatMapFlyTo(lat, lng){
                this.map.flyTo({lat: lat, lng:lng}, 4);
            },
            //<img src="+this.avatarUrl+" style='width:54px;height:54px;border-radius:50%;'>
            addMarker(){
                this.avatarMarker.addTo(this.map);
            },
            removeMarker(){
                this.map.removeLayer(this.avatarMarker)
            },
            // snapShot (verified){
            //     // console.log("snapShot");
            //     this.removeMarker();
            //     // setTimeout(
            //     //     ()=>{
            //     //         html2canvas(document.querySelector("#map"),{useCORS: true,}).then(canvas => {
            //     //         //base64
            //     //         const imgUrl = canvas.toDataURL("image/png");
            //     //             if(verified === "1"){
            //     //                 this.addMarker();
            //     //             }
            //     //             if(window["HeatMapShot"]){
            //     //                 window["HeatMapShot"].postMessage(imgUrl);
            //     //             }
            //     //     });},2000
            //     // )
            // },
            goBack(){
                this.$router.go(-1)
            }
        },
    }
</script>

<style scoped>
    #heatMap{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    .avatarIcon{
        color: red;
        width: 54px;
        height: 54px;
    }
    #map {
        width: 100%;
        height: calc(100vh);
        z-index: 1;
    }

</style>
