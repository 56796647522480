<script>
    import L from "leaflet";
    import { findRealParent, propsBinder } from 'vue2-leaflet';
    import 'leaflet-boundary-canvas';

    const props = {
      url: {
        type: String,
      },
      geojson: {
        type: Object,
      },
      tms: {
        type:Boolean,
        // default:false
      }
    };

    export default {
        name: 'LBoundaryCanvas',
        props,

        data() {
            return {
                ready: false,
            };
        },

        mounted() {

            const options = {
                boundary: this.geojson,
                tms: this.tms,
                crossOrigin: true,
            };

            this.mapObject = L.TileLayer.boundaryCanvas(this.url, options);

            L.DomEvent.on(this.mapObject, this.$listeners);
            propsBinder(this, this.mapObject, props);
            this.ready = true;
            this.parentContainer = findRealParent(this.$parent);
            this.parentContainer.addLayer(this);
        },

        beforeDestroy() {
            this.parentContainer.removeLayer(this);
        },

        methods: {

        },


        render() {
            return null;
        },
    };
</script>
