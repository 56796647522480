<template>
    <div class="bubbleNews">
        <l-map ref="map" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option" :minZoom="minZoom">
            <l-tile-layer v-if="infoUrlShow" ref="mapLayer" :url="infoUrl" :options="{tms:true}" />
           <!-- <l-tile-layer :url="citeUrl" :options="{tms:true}" />-->
            <l-custom-marker
                        v-for = "item in bubbleList"
                        :key="item.bubbleId"
                        :imgUrl="item.thumb"
                        :color1="'#ffffff'"
                        :color2="'rgba(0,0,0,0.5)'"
                        :latlon=[item.lat,item.lon]
                        :title="item.title"
                        :width="100"
                        :index="500"
                        @click="clickBubble(item.bubbleId)"
                />
            <!-- v-for="item in bubbleSingleDetail"-->
                <l-custom-marker
                        v-for="item in bubbleSingleDetail"
                        :key="item.bubbleId+'bubble'"
                        :imgUrl="item.thumb"
                        :color1="'#ecc012'"
                        :color2="'#ecc012'"
                        :latlon=[item.lat,item.lon]
                        :title="item.title"
                        :width="100"
                        :index="1000"
                        @click="clickBubble(item.bubbleId)"
                />
        </l-map>
        <!--顶部下载栏-->
        <TopShareBar v-if="share ==1" />

        <div v-if="share == 1" class="markInfoBar">
            <div style="width:100%;height:105px;margin-top:21px;padding-left:32px;white-space: nowrap;">
                <swiper ref="mSwiper" @slideChange="onPageChange()">
                    <swiper-slide v-for="(item,index) in bubbleList" :key="item.id" >
                        <div :key="index" class="markInfoBar-item" @click="clickBubble(item.bubbleId)">
                            <img :src="item.thumb" class="markInfoBar-item-image">
                            <div style="width: calc(100% - 119px);margin-top:15px;float:left;">
                                <p class="markInfoBar-item-title">{{item.title}}</p>
                                <p class="markInfoBar-item-poi">
                                    <span style="color:#675CB5;" class="iconfontFamily">&#xe61e;</span>
                                    {{item.poi}}
                                </p>
                                <p class="markInfoBar-item-describe">{{item.simpleInfo}}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div style="width: calc(100% - 64px);height:4px;margin:18px 32px 0 32px;display:flex">
                <div v-for="(item,index) in bubbleList" :key="index" :style="{'background':index===swiperIndex ? '#7f7f7f' : '#d8d8d8'}"
                     style="height:100%;margin-left:2px;margin-right:2px;flex:1;"></div>
            </div>
        </div>
        <!--弹窗展示-->
        <div v-if="share == 1&&cardViewShow" style="width: 100%;height: 100%;background-color: rgba(0,0,0,0.60);position: absolute; bottom:0;z-index: 9999;display:flex;justify-content: center; align-items:center">
            <div style="width: 320px;height: 532px;background-color:transparent;position:relative">
                <!--轮播-->
                <div style="width: 320px;height: 500px;margin-top: 0px;background-color: transparent;">
                    <swiper ref="cardSwiper" @slideChange="onCardViewPageChange()">
                        <swiper-slide v-for="(item,index) in bubbleSingleList" :key="index">
                            <div v-if="item.bubblePictureType == 1" style="width: 320px;height: 500px;background-color: white;border-radius:6px;position:relative">
                                <img :src="item.picture" style="width: 320px;height: 500px; border-radius:6px;" />
                                <div v-if="item.title!=''"  style="position:absolute; bottom:0px;height: 29px;width: 320px;background-color: rgba(0,0,0,0.35);border-radius: 0px 0px 6px 6px;color: white;font-size: 18px;padding-left: 23px">{{item.title}}</div>
                            </div>
                            <div v-if="item.bubblePictureType == 2" style="width: 320px;height: 500px;border-radius: 6px;background-color: white;">
                                <div style="width: 320px;height: 180px;position:relative">
                                    <img :src="item.picture" style="width: 320px;height: 180px;border-radius: 6px 6px 0px 0px" />
                                    <div v-if="item.title!=''" style="position:absolute; bottom:0px;height: 29px;width: 320px;background-color: rgba(0,0,0,0.35);color: white;font-size: 18px;padding-left: 23px">{{item.title}}</div>
                                </div>
                                <div class="noScrollDiv" style="width: 286px;height: 308px;overflow-y:auto;margin-left: 17px;margin-right: 17px;margin-top: 12px;white-space: pre-wrap">{{item.context}}</div>
                            </div>
                            <div v-if="item.bubblePictureType == 3" style="width: 320px;height: 500px;background-color: white;border-radius:6px">
                                <div style="width: 320px;height: 400px;position:relative">
                                    <img :src="item.picture" style="width: 320px;height: 400px; border-radius: 6px 6px 0px 0px" />
                                    <div v-if="item.title!=''" style="position:absolute; bottom:0px;height: 29px;width: 320px;background-color: rgba(0,0,0,0.35);color: white;font-size: 18px;padding-left: 23px">{{item.title}}</div>
                                </div>
                                <div class="noScrollDiv" style="width: 286px;height: 88px;overflow-y:auto;margin-left: 17px;margin-right: 17px;margin-top: 12px;white-space: pre-wrap">{{item.context}}</div>
                            </div>
                            <div v-if="item.bubblePictureType == 4" @click="showToast" >
                                <img :src="item.picture" style="width: 320px;height: 500px; border-radius:6px;" />
                                <div v-if="item.title!=''" style="position:absolute; bottom:0px;height: 29px;width: 320px;background-color: rgba(0,0,0,0.35);border-radius: 0px 0px 6px 6px;color: white;font-size: 18px;padding-left: 23px">{{item.title}}</div>
                                <div style="position:absolute; bottom:227px;left: 137px"><span style="color:#ffffff;font-size: 46px" class="iconfontFamily">&#xe66d;</span></div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
                <!--分页指示器-->
                <div style="display:flex;justify-content: center; align-items:center;background-color: transparent;margin-top: 10px">
                    <div v-for="(item,index) in bubbleSingleList" :key="index" :style="{'background':index===swiperCardIndex ? '#ffffff' : '#d8d8d8'}"
                         style="height: 8px;width:8px;margin-left:4px;margin-right:4px;border-radius: 50%"></div>
                </div>
                <!--关闭按钮-->
                <div style="position:absolute;width: 32px;height: 32px;background-color: transparent;border-radius: 50%;right: -16px;top: -16px;z-index: 999;display:flex;justify-content: center; align-items:center" @click="closeCard()">
                    <div style="width: 20px;height: 20px;background-color: white;border-radius: 50%;display:flex;justify-content: center; align-items:center">
                        <span class="iconfontFamily" style="color: #cccccc;font-size:10px;">&#xe72c;</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="share==1&&shareTip" id="shareTip">打开共生地球App,体验更多功能</div>
        <!--<ShareButton v-if="share==1" style="position:absolute; transform: translateY(-24px)"/>-->
    </div>
</template>

<script>
    import L from "leaflet";
    import { LMap, LTileLayer} from 'vue2-leaflet';
    import TopShareBar from '@/components/TopShareBar';
   // import ShareButton from "@/components/ShareButton";
    import LCustomMarker from "@/components/Vue2LeafletCustomMarker"
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    export default {
        name: "BubbleNews",
        components:{
            LMap,
            LTileLayer,
          //  ShareButton,
            LCustomMarker,
            swiper,
            swiperSlide,
            TopShareBar,
           // LMarker,
        },
        data(){
            return{
                zoom: 12,
                minZoom:2,
                center: L.latLng(40.074, 116.604),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
                mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                //infoUrl:'https://map.charmingglobe.com/tile/china1m/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
                infoUrl:'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},
                infoId:'',
                bubbleList:[
                    {
                        bubbleId: "202112101240000067339",
                        bubbleType: 2,
                        gmtCreatetime: "2021-12-10 12:40:06",
                        gmtModifytime: "2022-04-12 11:25:08",
                        infoId: "202112101128000018152",
                        lat: 40.54894544438986,
                        lon: 115.80451672884567,
                        poi: "延庆冬奥村",
                        simpleInfo: "下俯瞰。",
                        thumb: "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202112101235000413721_thumb.png",
                        title: "高山滑雪中心"
                    },
                ],
                citeUrl:'',//图层地址
                share:0,//是否分享 0不分享 1分享
                bubbleDetail:{
                    infoId: "",
                    simpleInfo: "",
                    thumbPicture: "https://cgwxforum.obs.cn-north-4.myhuaweicloud.com:443/202112101115000405980_thumb.jpg",
                    title: "冬奥延庆赛区",
                    userId: ""
                },
                bubbleSingleDetail:[],
                swiperIndex:0,
                swiperCardIndex:0,
                cardViewShow:false,
                bubbleSingleList:[],
                infoUrlShow:false,
                shareTip: false,
            }
        },
        methods:{
            getBubbleNew(){
                this.bubbleList = []
                this.bubbleSingleDetail = []
                this.$http.get('/earthApi/main-info/getNewsBubbleListnew?infoId='+this.infoId).then(response => {
                    if(response.body.status == 200){
                        this.infoUrlShow = true
                        this.bubbleList = response.body.data.newsBubbleList
                        /* for (var i = 0;i<response.body.data.newsBubbleList.length;i++){
                             var bubble={
                                 bubbleId: response.body.data.newsBubbleList[i].bubbleId,
                                 bubbleType: response.body.data.newsBubbleList[i].bubbleType,
                                 gmtCreatetime: response.body.data.newsBubbleList[i].gmtCreatetime,
                                 gmtModifytime: response.body.data.newsBubbleList[i].gmtModifytime,
                                 infoId: response.body.data.newsBubbleList[i].infoId,
                                 lat: response.body.data.newsBubbleList[i].lat,
                                 lon: response.body.data.newsBubbleList[i].lon,
                                 poi: response.body.data.newsBubbleList[i].poi,
                                 simpleInfo: response.body.data.newsBubbleList[i].simpleInfo,
                                 thumb: response.body.data.newsBubbleList[i].thumb,
                                 title: response.body.data.newsBubbleList[i].title
                             }
                             this.bubbleList.push(bubble)
                         }*/
                        if(this.bubbleList.length != 0){
                            this.bubbleSingleDetail.push(this.bubbleList[0])
                        }
                        this.maxBounds =  L.geoJSON(JSON.parse(response.body.data.citeInfo.maxgeo)).getBounds()
                        this.$refs.map.mapObject.setView(L.latLng(this.bubbleList[0].lat,this.bubbleList[0].lon),response.body.data.zoom)
                        this.minZoom = response.body.data.citeInfo.maxZoomInteger
                        //this.$refs.map.mapObject.fitBounds(L.geoJSON(JSON.parse(response.body.data.citeInfo.geo)).getBounds())
                        // this.citeUrl =  'https://map.charmingglobe.com/tile/dataset/' + list.citeInfo.cite+ '/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf'
                        /* this.bubbleList.forEach((item)=>{
                                 let icon = L.divIcon({
                                     className:'custom-div-icon',
                                     html:  "<div><div style='position: absolute;width: 42px;height:42px;transform: rotate(-45deg);background-color: #ffffff;border-radius: 50% 50% 50% 0;'></div>"+
                                         "<div style='position: absolute;background-color: #ffffff;width: 50px;height: 50px;border: 3px solid rgba(151,151,151,0.00);border-radius: 50%;top: -18px;left:-7px'><img style='border-radius: 50%' src='"+item.thumb+"'width='49' height='49'/></div>"+
                                         "<div style='position: absolute;height: 22px;width: 83px;background-color:#ffffff;border-radius: 11px;text-align: center;line-height: 22px;top:55px;left: -21px'>"+item.title+"</div> </div>",
                                     iconSize: [128, 83],
                                     iconAnchor: [64, 83]
                                 })
                                 // eslint-disable-next-line no-unused-vars
                                 L.marker([item.lat,item.lon], {icon:icon}).addTo(this.$refs.map.mapObject).on('click',function(e){
                                     // eslint-disable-next-line no-undef
                                     OpenBubbleNew.postMessage(item.bubbleId);
                                 })

                         })*/
                        //禁止移动
                        /*this.$refs.map.mapObject.dragging.disable()
                        this.$refs.map.mapObject.doubleClickZoom.disable()*/
                    }else{
                        this.$router.push(
                            {
                                path: '/NotExist',
                            }
                        )
                    }

                })
            },
            getBubbleSingleList(bubbleId){
                this.$http.get('/earthApi/main-info/getBubbleByBubbleId?bubbleId='+bubbleId).then(response=> {
                    if(response.body.status == 200){
                        if(response.body.data.bubbleType == 0){
                            this.bubbleSingleList = response.body.data.pictureList
                            this.cardViewShow = true
                        }else{
                            this.showToast()
                        }
                    }

                })
            },
            showToast(){
                this.shareTip = true
                setTimeout(()=>{this.shareTip = false},3000)
            },
            clickBubble(val){
                if(this.share == 0){
                    // eslint-disable-next-line no-undef
                    OpenBubbleNew.postMessage(val);
                    this.jumpTo(val);
                }else{
                    this.getBubbleSingleList(val)
                }

            },
            onPageChange(){
                this.swiperIndex = this.$refs.mSwiper.swiper.activeIndex
                this.bubbleSingleDetail = []
                this.bubbleList.find((item,index)=>{
                    if(index ==this.swiperIndex){
                        this.bubbleSingleDetail.push(item)
                        this.$refs.map.mapObject.flyTo(L.latLng(item.lat,item.lon),parseInt(this.$refs.map.mapObject.getZoom()))
                    }
                })
            },
            onCardViewPageChange(){
                this.swiperCardIndex  = this.$refs.cardSwiper.swiper.activeIndex
            },
            closeCard(){
                this.cardViewShow = false
                this.swiperCardIndex = 0
            },
            //分享的详情
            getBubbleNewDetail(){
                this.$http.get('/earthApi/main-info/getNewsBubbleDetail?infoId='+this.infoId+ '&shareType= 1').then(response=> {
                    if(response.body.status == 200){
                        this.bubbleDetail = response.body.data
                        document.title = response.body.data.title
                    }
                })
            },
            jumpTo(bubbleId){
                this.bubbleSingleDetail = []
                this.bubbleList.find((item)=>{
                    if(item.bubbleId ==bubbleId){
                        this.bubbleSingleDetail.push(item)
                        this.$refs.map.mapObject.flyTo(L.latLng(item.lat,item.lon),parseInt(this.$refs.map.mapObject.getZoom()))
                    }
                })

            },
        },
        mounted() {
            window.leaflet = this
            this.infoId = this.$route.query.infoId
            this.share = this.$route.query.share
            this.getBubbleNew()
            this.getBubbleNewDetail()
        }
    }
</script>

<style lang="scss" scoped>

    .bubbleNews {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //text-align: center;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
    // 禁止长按选择文字
    -webkit-user-select: none;

    // 禁止长按呼出菜单
    -webkit-touch-callout: none;
}
    .bubbleNews p{
    margin:0;
}
    .titleBar {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:64px;
        background:#F4F4F4;
        color:#4a4a4a;
        font-size:16px;
        font-weight:bold;
        text-align:center;
        z-index: 999;
    }
    .markInfoBar{
    position:absolute;
    bottom:0;
    width:100%;
    height:180px;
    background:#ffffff;
    border-radius:12px 12px 0 0;
    z-index:999;
    overflow-x: hidden;
}
    .markInfoBar-item{
    width:calc(100% - 64px);
    height:105px;
    border-radius:8px;
    box-shadow:0 0 10px 0 rgba(44,44,44,0.25);
    margin-right:32px;
    display:inline-block;
    white-space: normal;
}
    .markInfoBar-item-image{
    width:100px;
    height:75px;
    border-radius:12px;
    margin:15px 5px 0 9px;
    float:left;
}
    .markInfoBar-item-title{
    color:#4A4A4A;
    font-size:14px;
}
    .markInfoBar-item-poi{
    margin-top:3px !important;
    color:#4A4A4A;
    font-size:10px;
}
    .markInfoBar-item-describe{
    margin-top:3px !important;
    color:#4A4A4A;
    font-size:12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
    .shareButton{
        width: 75px;
        height: 28px;
        background: #695EC1;
        border-radius: 25px;
        float: right;
        margin-right: 15px;
        margin-top: 13px;
        #openApp{
            text-decoration: none;
            display: block;
            color: white;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            line-height: 28px;
            height: 28px;
            background: #695EC1;
            border-radius: 27px;
            box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
        }
    }
    .noScrollDiv::-webkit-scrollbar{
        width: 0;
    }
    #shareTip{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 244px;
        height: 60px;
        background-color: rgba(0,0,0,0.6);
        border-radius: 11px;
        line-height: 60px;
        font-size: 14px;
        color: white;
        text-align: center;
        z-index: 9999;
    }
</style>
