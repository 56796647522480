<template>
    <!--弹窗类资讯 1图文文章 7tips 18联想文章-->
    <div id="articleSlidingUpPanel">

        <!--加载中动画-->
        <div v-if="loading">
            <div class="loadingArea" style="width:100%;height:25px;margin-bottom:15px;">
                <div class="loadingArea_white"></div>
            </div>
            <div style="margin-bottom:5px;overflow:hidden;">
                <div class="loadingArea" style="width:32px;height:32px;border-radius:50%;float:left;">
                    <div class="loadingArea_white"></div>
                </div>
                <div class="loadingArea" style="width:100px;height:24px;margin-top:4px;margin-left:6px;float:left;">
                    <div class="loadingArea_white"></div>
                </div>
            </div>
            <div class="loadingArea" style="width:100%;height:25px;margin-bottom:5px;">
                <div class="loadingArea_white"></div>
            </div>
            <div class="loadingArea" style="width:100%;height:300px;">
                <div class="loadingArea_white"></div>
            </div>
        </div>

        <!--文章内容-->
        <div id="articleSlidingUpPanelContent">
            <!--标题-->
            <div v-if="articleDetail.mainType === 1 || articleDetail.mainType === 7" class="titleLine" style="overflow:hidden;">
                <p style="width:calc(100% - 65px);float:left;">
                    <!--<span v-if="articleDetail.code!=''" class="iconfontFamily" :style="iconColor(this.articleDetail.color, this.articleDetail.color2)" v-html="iconCoder(articleDetail.code)"></span>-->
                    <span>
                        <img :src="articleDetail.icon" style="height:16px;position:relative;top:2px;">
                    </span>
                    {{articleDetail.title}}
                </p>
                <div v-if="articleDetail.mainType === 7" @click="createTipsByTopic"
                     style="padding:0;margin:0 2px 0 0;border:none;float:right;font-weight:normal;" class="focusBtn">
                    <p style="line-height:25px;">+ 参与话题</p>
                </div>
            </div>

            <!--用户名-->
            <div v-if="articleDetail.mainType === 1 || articleDetail.mainType === 7" class="userLine">
                <img :src="articleDetail.avatar" @click="intoUserPage" class="circleAvatar">

                <div style="height:100%;float:left;">
                    <p class="userName">{{articleDetail.nick}}</p>
                    <p class="createTime">{{articleDetail.timeDisplay}}</p>
                </div>

                <div v-if="articleDetail.focusStatus === 0" @click="focusUser" style="margin-left:0;float:right;" class="focusBtn">
                    <p style="line-height:20px;">+ 关注</p>
                </div>
                <div v-if="articleDetail.focusStatus === 1" @click="unfocusUser"
                     style="border:1px solid #8a8a8a;color:#8a8a8a;margin-left:0;float:right;" class="focusBtn">
                    <p style="line-height:20px;">已关注</p>
                </div>

                <!--&lt;!&ndash;tips 参与话题按钮&ndash;&gt;-->
                <!--<div @click="createTipsByTopic" v-if="articleDetail.mainType === 7" style="margin-left:0;float:right;" class="focusBtn">-->
                    <!--<p style="line-height:20px;">+ 参与话题</p>-->
                <!--</div>-->

            </div>
            <!--地址-->
            <div v-if="articleDetail.mainType === 1 || articleDetail.mainType === 7" @click="mapToAddress" class="addressLine">
                <p style="line-height:25px;">
                    <span class="addressIcon iconfontFamily">&#xe61e;</span>
                    {{articleDetail.geoName}}
                </p>
            </div>
            <!--图文文章正文-->
            <div class="contextHtmlLine" v-if="articleDetail.mainType === 1" v-html="articleDetail.context"></div>

            <!--tips正文+配图-->
            <div v-if="articleDetail.mainType === 7">
                <p class="contextLine">{{articleDetail.context}}</p>
                <!--配图-->
                <div v-if="articleDetail.detailInfoId === ''">
                    <!--普通tips-->
                    <div @click="intoFullScreenImage(index)" v-for="(item,index) in articleDetail.picList" :key="index" class="contextImage"
                         :style="{'background-image':'url(' + articleDetail.picList[index] +')','margin-right':(index + 1) % 3 != 0 ? '10px' : '0'}"></div>
                </div>
                <div v-if="articleDetail.detailInfoId !== ''" :style="{'background-image':'url(' + articleDetail.picture1 +')'}" class="contextImageRecord">
                    <!--record转tips-->
                    <p @click="intoRecord" class="contextImageRecord_btn">
                        查看详情
                        <span style="font-size:10px;" class="iconfontFamily">&#xe697;</span>
                    </p>
                </div>
            </div>

            <!--联想文章-->
            <div v-if="articleDetail.mainType === 18">
                <!--系列信息-->
                <p style="margin-bottom:6px;" class="titleLine">{{articleDetail.mainTag}}</p>
                <p style="margin-bottom:14px;font-size:10px;color:#9E9E9E;">共{{articleDetail.newsList.length}}个位置</p>
                <p style="margin-bottom:16px;font-size:12px;color:#4A4A4A;white-space: pre-wrap;" v-html="articleDetail.mainTagIntroduce"></p>
                <!--系列各项 横向滚动-->
                <div class="seriesList">
                    <div @click="changeSeriesItem(item)" v-for="(item,index) in articleDetail.newsList" :key="index"  class="seriesListItem"
                         :style="{'margin-left':index===0?'11px':'0','margin-right':index===articleDetail.newsList.length-1?'11px':'0'}">
                        <div :style="[{'background-image':'url(' + articleDetail.newsList[index].thumbPicture +')'},seriesCurrThumbStyle(index + 1)]" class="seriesListItem_thumb">
                            <p v-if="seriesCurrIndex!==index + 1">{{toDoubleDigit(index+1)}}</p>
                        </div>
                        <div v-if="index !== articleDetail.newsList.length - 1" class="seriesListItem_line"></div>
                        <p :style="{'left':seriesCurrIndex===index + 1?'25px':'21px'}" class="seriesListItem_text">{{item.title}}</p>
                    </div>
                </div>
                <!--该项文章的内容-->
                <div class="contextHtmlLine" v-html="articleDetail.context"></div>

            </div>
        </div>

        <!--分享页 下载栏-->
        <div v-if="share" class="bottomBar">
            <ShareButton style="position:absolute;"></ShareButton>
        </div>

    </div>


</template>

<script>
    import ShareButton from "@/components/ShareButton";
    export default {
        name:"ArticleSlidingUpPanel",
        components: {
            ShareButton,
        },
        data(){
            return{
                // infoId:"202201251020000495026",
                // token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZSI6IjE4NzQ0MDIzMDAxIiwiZXhwIjoxNjM4NTA0NjIyLCJ1c2VySWQiOjEyMzQ1NiwiZW1haWwiOiIifQ.LXTU1ALdORIqNB3vFsgVq37vls_7xD8OL4XHgwHaTrQ",
                // imei:"2669df49-1e60-4f98-8b50-768537fe5d98",
                infoId:"",
                token:"",
                imei:"",
                share: false,

                loading:true,
                articleDetail:{},

                //联想文章 当前展示的是系列中第几项（从1开始）
                seriesCurrIndex:1,
                // seriesMapEaseTo:false,


            }
        },
        computed:{
            toDoubleDigit(){
                return (num)=>{
                    if(num >= 10){
                        return num;
                    }
                    else{
                        return "0"+num;
                    }
                }
            },
            seriesCurrThumbStyle(){
                return (sequences)=>{
                    if(sequences === this.seriesCurrIndex){
                        return {
                            'width':'50px',
                            'height':'50px',
                            'margin-top':'-4px',
                        }
                    }
                    else{
                        return{
                            'border':'1px solid #FFDA96'
                        }
                    }

                }


            }
        },
        methods:{
            //计算icon的code和color
            iconCoder(val){
                return '&#' + val.substr(1) + ';'
            },
            iconColor(val,val2){
                if(val2 == ''){
                    return 'color:#'+this.argbToRgba(val.substr(2))
                }else {
                    return '-webkit-background-clip:text;-webkit-text-fill-color: transparent; background-image:-webkit-linear-gradient(top,#'+this.argbToRgba(val.substr(2))+',#'+this.argbToRgba(val2.substr(2))+')'
                }
            },
            argbToRgba(val){
                return val.slice(2) + val.slice(0,2)
            },


            //加载内容
            createPageContent(){
                let url;
                if(this.share){
                    url = '/earthApi/main-info/getMainInfoDetail?infoId='+this.infoId + '&shareType=1'
                }
                else{
                    url = '/earthApi/main-info/getMainInfoDetail?infoId='+this.infoId
                }

                //获取文章详情
                this.$http.get(url, this.share ? "" :{headers:{'Authorization': 'Bearer ' + this.token,'imei':this.imei}}).then(response => {
                    this.articleDetail = response.body.data;
                    this.loading = false;

                    //数据详情传给flutter
                    if(window["flutter_articleInfo"]){
                        window["flutter_articleInfo"].postMessage(JSON.stringify(this.articleDetail));
                    }

                    if(this.articleDetail.mainType === 1 || this.articleDetail.mainType === 18){

                        setTimeout(()=>{
                            //卷帘设定高度
                            let iframeList = document.querySelectorAll("iframe");
                            for(let i=0;i<iframeList.length;i++){
                                iframeList[i].width = "100%";
                                if(iframeList[i].getAttribute("src").indexOf("CompareHorizontal") !== -1){
                                    //水平卷帘
                                    iframeList[i].height = window.innerWidth / 2;
                                }
                                else{
                                    //垂直卷帘
                                    iframeList[i].height = window.innerWidth;
                                }
                                //插入按钮
                                let iframeBtn = document.createElement("div");


                                iframeBtn.innerHTML = '<div onclick="intoCompareFullScreen()" style="position: absolute;right: 8px;top:8px;z-index: 10;color:#ffffff;" class="iconfontFamily">&#xe719;</div>';
                                iframeList[i].parentNode.appendChild(iframeBtn.childNodes[0]);
                            }

                            //视频隐藏下载和画中画功能，用来隐藏视频的更多按钮
                            let videoList = document.querySelectorAll("video");
                            for(let i=0;i<videoList.length;i++){
                                videoList[i].setAttribute("controlsList","nodownload");
                                videoList[i].setAttribute("disablepictureinpicture","true");
                            }

                        },100)

                    }

                    //获取页面高度传给flutter
                    setTimeout(()=>{
                        if(window["flutter_articleHeight"]){
                            window["flutter_articleHeight"].postMessage(document.getElementById('articleSlidingUpPanel').clientHeight);
                        }
                    },500)

                    if(this.articleDetail.mainType === 18){
                        //当前是系列中第几个文章
                        this.seriesCurrIndex = this.articleDetail.thisNumber;
                    }

                })

            },
            //进入全屏卷帘
            intoCompareFullScreen(){
                var iframeSrc = event.target.previousSibling.getAttribute("src");

                if(this.share === true){
                    //是分享页，web跳转页面到全屏

                    //从src中分解参数
                    let firsturl = iframeSrc.split('?');
                    let iframeSrcObj ={};
                    let secondurl = firsturl[1].split('&');
                    for(let i=0;i<secondurl.length;i++){
                        let thirdurl = secondurl[i].split('=');
                        iframeSrcObj[thirdurl[0]] = thirdurl[1];
                    }

                    if(iframeSrc.indexOf("CompareHorizontal") !== -1){
                        //水平卷帘
                        this.$router.push(
                            {
                                path: '/Article/CompareHorizontal',
                                query: {
                                    topLayerCite: iframeSrcObj.topLayerCite,
                                    bottomLayerCite: iframeSrcObj.bottomLayerCite,
                                    areaGeo: iframeSrcObj.areaGeo,
                                    title: this.articleDetail.title
                                }
                            }
                        )
                    }
                    else{
                        //垂直卷帘
                        this.$router.push(
                            {
                                path: '/CrowdShoot/Compare',
                                query: {
                                    topLayerCite: iframeSrcObj.topLayerCite,
                                    bottomLayerCite: iframeSrcObj.bottomLayerCite,
                                    areaGeo: iframeSrcObj.areaGeo,
                                    title: this.articleDetail.title
                                }
                            }
                        )
                    }

                }
                else{
                    //不是分享页，是flutter嵌入的长文章

                    //从src中分解参数
                    let firsturl = iframeSrc.split('?');
                    let iframeSrcObj ={};
                    let secondurl = firsturl[1].split('&');
                    for(let i=0;i<secondurl.length;i++){
                        let thirdurl = secondurl[i].split('=');
                        iframeSrcObj[thirdurl[0]] = thirdurl[1];
                    }
                    let flutterFullScreenCompareSrc = firsturl[0]+"?topLayerCite="+iframeSrcObj.topLayerCite+"&bottomLayerCite="+iframeSrcObj.bottomLayerCite
                        +"&areaGeo="+iframeSrcObj.areaGeo+"&title="+this.articleDetail.title+"&titleShow=false"

                    // let iframeSrcList = iframeSrc.split("&compareFullScreen");
                    // let flutterFullScreenCompareSrc = iframeSrcList[0];
                    if(window["flutter_intoCompareFullScreen"]){
                        window["flutter_intoCompareFullScreen"].postMessage(flutterFullScreenCompareSrc);
                    }
                }
            },


            //点击用户头像
            intoUserPage(){
                if(window["flutter_intoUserPage"]){
                    window["flutter_intoUserPage"].postMessage("");
                }
            },
            //点击地址栏
            mapToAddress(){
                if(window["flutter_mapToAddress"]){
                    window["flutter_mapToAddress"].postMessage("");
                }
            },

            //点击关注按钮
            focusUser(){
                if(window["flutter_focusUser"]){
                    window["flutter_focusUser"].postMessage("");
                }
            },
            unfocusUser(){
                if(window["flutter_unfocusUser"]){
                    window["flutter_unfocusUser"].postMessage("");
                }
            },
            focusUserChangeStatus(state){
                this.articleDetail.focusStatus = state;
            },

            //参与话题
            createTipsByTopic(){
                if(window["flutter_createTipsByTopic"]){
                    window["flutter_createTipsByTopic"].postMessage("");
                }
            },

            //tips看大图
            intoFullScreenImage(index){
                if(window["flutter_intoFullScreenImage"]){
                    window["flutter_intoFullScreenImage"].postMessage(index.toString());
                }
            },

            //跳转到record
            intoRecord(){
                if(window["flutter_intoRecord"]){
                    window["flutter_intoRecord"].postMessage("");
                }
            },

            //flutter调用，重新获取新内容
            createPageNewContent(param){
                this.infoId = param.infoId
                this.token = param.token
                this.imei = param.imei
                this.loading = true;
                this.createPageContent();
            },



            //联想文章 切换文章
            changeSeriesItem(item){
                // this.seriesMapEaseTo = true;
                this.infoId = item.infoId;
                this.createPageContent();
                if(window["flutter_showSeriesItemMapBubble"]){
                    window["flutter_showSeriesItemMapBubble"].postMessage(item.infoId);
                }
                if(window["flutter_closePanel"]){
                    window["flutter_closePanel"].postMessage("");
                }
                if(window["flutter_mapToAddress"]){
                    window["flutter_mapToAddress"].postMessage(JSON.stringify({
                        lon:item.lon,
                        lat:item.lat
                    }));
                }
            }

        },

        created(){
            window.intoCompareFullScreen = this.intoCompareFullScreen;//字符串中的方法 这样写就可以获取到
        },
        mounted(){
            window.articleSlidingUpPanel = this;
            this.infoId = this.$route.query.infoId
            this.token = this.$route.query.token
            this.imei = this.$route.query.imei
            if(this.$route.query.share){
                this.share = true;
                //修改样式
                document.getElementById("app").setAttribute("style","overflow:auto;");
                document.getElementById("articleSlidingUpPanelContent").setAttribute("style","margin-bottom:64px;margin-top:20px;");
            }

            this.createPageContent();

        }

    }

</script>

<style scoped>


    #articleSlidingUpPanel{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /*text-align: center;*/
        color: #2c3e50;
        position: absolute;
        width: calc(100% - 40px);
        /*height: 100%;*/
        z-index: 0;
        overflow: hidden;
        margin:0 20px;
    }

    #articleSlidingUpPanel p{
        margin:0;
    }

    /*loading动画效果*/
    @-webkit-keyframes loading {
        0%{left:0;}
        100%{left:100%;}
    }
    @keyframes loading{
        0%{left:0;}
        100%{left:100%;}
    }
    .loadingArea{
        position:relative;
        background:#d8d8d8;
        border-radius:4px;
        overflow:hidden;
    }
    .loadingArea_white{
        position:absolute;
        top:0;
        left:50%;
        width:6px;
        height:100%;
        border-radius:4px;
        background: -webkit-linear-gradient(left, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* Firefox 3.6 - 15 */
        background: linear-gradient(to right, #dfdfdf 0%, #f8f8f8 48%, #ffffff 50%, #f8f8f8 52%, #dfdfdf 100% ); /* 标准的语法 */
        box-shadow: 0 0 20px #ffffff;
        animation:loading 1s infinite;
        -webkit-animation:loading 1s infinite;
    }



    .fullScreenBtn{
        position: absolute;
        right: 8px;
        top:8px;
        z-index: 10;
    }

    .titleLine{
        margin-bottom:15px;
        font-size:16px;
        color:#000000;
        font-weight:bold;
    }
    .userLine{
        width:100%;
        height:32px;
        margin-bottom:10px;
    }
    .circleAvatar{
        width:28px;
        height:28px;
        border-radius:50%;
        margin-right:3px;
        float:left;
    }
    .userName{
        margin:1px 0 2px 0;
        font-size:12px;
        color:#000000;
    }
    .createTime{
        font-size:10px;
        color:#979797;
    }
    .focusBtn{
        /*width:45px;*/
        height:20px;
        border:1px solid #675CB5;
        border-radius:12px;
        margin:6px 0 0 29px;
        padding:0 10px;
        float:left;
        font-size:12px;
        color:#675CB5;
        text-align:center;
    }

    .addressLine{
        width:calc(100% - 20px);
        height:25px;
        background:#F5F5F6;
        border-radius:6px;
        margin-bottom:10px;
        padding:0 10px;
        font-size:10px;
        color:#979797;
    }
    .addressIcon{
        -webkit-background-clip:text;
        -webkit-text-fill-color: transparent;
        background-image:-webkit-linear-gradient(top,#418BFF,#3A46E3);
    }

    .contextHtmlLine{
        font-size:14px;
        color:#000000;
    }


    .contextLine{
        margin-bottom:10px;
        font-size:14px;
        color:#000000;
    }
    .contextImage{
        width:calc((100vw - 60px) / 3);
        height:calc((100vw - 60px) / 3);
        margin-bottom:10px;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
        float:left;
    }
    .contextImageRecord{
        position:relative;
        width:100%;
        height:160px;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
    }
    .contextImageRecord_btn{
        position:absolute;
        bottom:6px;
        right:4px;
        font-size:12px;
        color:#ffffff;
    }


    .seriesList{
        width:100%;
        height:68px;
        margin-bottom:20px;
        white-space:nowrap;
        overflow-x:auto;
        overflow-y:hidden;
    }
    .seriesList::-webkit-scrollbar{
        display:none;
    }

    .seriesListItem{
        position:relative;
        height:calc(100% - 4px);
        margin-top:4px;
        display: inline-block;
    }
    .seriesListItem_thumb{
        position:relative;
        width:39px;
        height:39px;
        border-radius:50%;
        background-repeat:no-repeat;
        background-position:center;
        background-size:cover;
        float:left;
    }
    .seriesListItem_thumb p{
        width:100%;
        height:100%;
        border-radius:50%;
        background:rgba(117,137,251,0.7);
        font-size:14px;
        color:#ffffff;
        text-align:center;
        line-height:39px;
    }
    .seriesListItem_line{
        width:43px;
        height:1px;
        border-bottom:1px dashed #979797;
        margin-top:21px;
        float:left;

    }
    .seriesListItem_text{
        position:absolute;
        bottom:0;
        transform:translate(-50%);
        max-width:65px;
        line-height:13px;
        font-size:10px;
        color:#4a4a4a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; /*设置隐藏部分为省略号*/
    }


    /*分享按钮*/
    .bottomBar{
        width:100%;
        height: 64px;
        background: #ffffff;
        border-radius: 16px 16px 0px 0px;
        box-shadow: 0px 2px 4px 0px #000000;
        position: fixed;
        left: 0;
        bottom: 0;
    }



</style>


<style>
    /*html文章中 视频功能按钮隐藏*/
    .contextHtmlLine video::-webkit-media-controls-fullscreen-button {
        display: none !important;
    }
    .contextHtmlLine video::-webkit-media-controls-volume-control-container {
        display: none !important;
    }

</style>
