<template>
    <div id = "miniMap">
        <div id="map"></div>
    </div>
</template>

<script>
    import L from "leaflet";

    export default {
        name: "miniMap",
        data(){
            return {
                map: "",
                // 活动坐标
                lat:43.8,
                lng:125.35,
                // 用户位置
                userLat:43.8,
                userLng:125.35,
            }
        },
        mounted() {
            this.userLat = this.$route.query.userLat
            this.userLng = this.$route.query.userLng
            this.lat = this.$route.query.lat
            this.lng = this.$route.query.lng
            // this.lat = 42.016776
            // this.lng = 122.866418
            // console.log("-------------------------")
            // console.log(this.userLat)
            // console.log(this.userLng)
            // console.log(this.lat)
            // console.log(this.lng )
            this.initMap();
        },
        methods:{
            initMap(){
                const baseLayer = L.tileLayer(
                    "https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543",
                    {maxZoom: 18, minZoom: 5,subdomains: ['0', '1', '2', '3']}
                );
                const baseLayer2 = L.tileLayer(
                    "https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543",
                    {maxZoom: 10, minZoom: 3,subdomains: ['0', '1', '2', '3']}
                );
                this.map = L.map("map", {
                    center: [this.userLat , this.userLng],
                    minZoom:1,
                    maxZoom: 18,// 地图中心
                    zoom: 13, //缩放比列
                    zoomControl: false, //禁用 + - 按钮
                    doubleClickZoom: true, // 禁用双击放大
                    attributionControl: false,// 移除右下角leaflet标识
                    crs: L.CRS.EPSG3857,
                    layers:[baseLayer,baseLayer2],
                });
                var polygon1 = new L.Circle([this.lat , this.lng], 1000, {
                    color: '', //颜色
                    fillColor: 'blue',
                    fillOpacity: 0.4, //透明度
                });
                polygon1.addTo(this.map)
                let icon= L.icon({
                    iconUrl: require('../../assets/Tips/position.png'),//marker图片地址
                    iconSize: [15, 25],//marker宽高
                    // iconAnchor: [28.5, 71]//marker中心点位置
                })
                L.marker([this.userLat, this.userLng], { icon: icon }).addTo(this.map)
            },
        }
    }
</script>

<style scoped>
    #miniMap{
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
    #map {
        width: 100%;
        height: calc(100vh);
        z-index: 1;
    }
</style>
