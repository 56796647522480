<template>
    <div class="OrbitSatellite">
       <!-- <model-obj
                v-if="modelUrl!=''"
                :src="modelUrl"
                backgroundColor="#000000"
        ></model-obj>-->
       <!-- <model-stl
                v-if="modelUrl!=''"
                :src="modelUrl"
                backgroundColor="#000000"
        ></model-stl>-->
        <!--backgroundColor="#000000"-->
        <model-gltf
                v-if="modelUrl!=''"
                :src="modelUrl"
                :lights="lights"
        ></model-gltf>
    </div>


</template>

<script>
    // import { ModelGltf } from 'vue-3d-model'
    export default {
        name: "OrbitSatellite",
        components: {
            // ModelGltf
        },
        data(){
            return{
                modelUrl:'https://mall.charmingglobe.com/ttf/KF01_C4_FBZK_20220502.gltf',
                lights: [
                  /*  {
                        type: 'DirectionalLight',
                        position: { x: 1, y: 1, z: 1 },
                        color: 0x2DFF34,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: -1, y: 1, z: 1 },
                        color: 0x2DFF34,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: -1, z: 1 },
                        color: 0x2DFF34,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: 1, z: -1 },
                        color: 0x2DFF34,
                        intensity: 1,
                    },*/
                    {
                        type: 'AmbientLight',
                        color: 0xffffff,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 0, y: 0, z: 0 },
                        color: 0xffffff,
                        intensity: 11,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: -1, y: 1, z: 1 },
                        color: 0xffffff,
                        intensity:1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: -1, z: 1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: 1, z: -1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: -1, y: 1, z: -1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: -1, y: -1, z: 1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: 1, y: -1, z: -1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                    {
                        type: 'DirectionalLight',
                        position: { x: -1, y: -1, z: -1 },
                        color: 0xffffff,
                        intensity: 1,
                    },
                ]
            }
        },
        mounted() {
            window.leaflet = this
            this.modelUrl = this.$route.query.modelUrl
        }
    }
</script>

<style lang="scss" scoped>
    .OrbitSatellite {
        font-family: 微软雅黑;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //text-align: center;
        color: #2c3e50;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
        background-color: #000000;
    }

</style>
