<template>
    <div id="main" :style="{backgroundImage:'url('+ require('@/assets/openApp.jpg') +')'}" >
<!--        <div class="button"><a id="gotoSurvey" href="https://www.wjx.cn/vj/QROpS5t.aspx">立即预约内测名额</a></div>-->
        <ShareButton />
    </div>
</template>

<script>

import ShareButton from "@/components/ShareButton";
export default {
    name: "OpenApp",
    components: {ShareButton},
    mounted() {
        window.location.href="charmingearth://earth.jl1.cn?infoId=" + this.$route.query.infoId + '&type=' + this.$route.query.type
    }
}
</script>

<style scoped lang="scss">

#main{
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    width: 100%;
    height: 100%
}


.button{
    background: rgba(0,0,0,0);
    position: absolute;
    left: 0;
    right: 0;
    margin:0 34px;
    bottom: 100px;
    z-index: 9999;
    #gotoSurvey{
        text-decoration: none;
        display: block;
        color: white;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        line-height: 40px;
        height: 40px;
        background: linear-gradient(230deg,#6d61d1 14%, #61589d 83%);
        border-radius: 6px;
        box-shadow: 0px 2px 10px 0px rgba(95,95,95,0.50);
    }
}
</style>
