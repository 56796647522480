<template>
    <div style="position:absolute;width:100%;height:100%;">
        <l-map ref="shootPlanPageMap" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
            <l-tile-layer ref="mapLayer" :url="mapUrl" :options="mapSubdomains" />
            <l-tile-layer ref="mapLayer" :url="infoUrl" :options="mapSubdomains" />
        </l-map>
    </div>

</template>

<script>
    import 'leaflet/dist/leaflet.css'
    import L from 'leaflet'
    import { LMap, LTileLayer } from 'vue2-leaflet';
    export default {
        name:"ShootPlanPage",
        components: {LMap, LTileLayer},
        data(){
            return{
                //地图
                zoom: 12,
                center: L.latLng(36.2604541134187, 117.081861669118),
                maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
                option: {doubleClickZoom:false, zoomControl: false, attributionControl: false, renderer: L.canvas(), dragging:false,touchZoom:false,},
                mapUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                infoUrl:'https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=05861044e5fe4135647facbd39abc543',
                // chinaOneUrl:'https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf',
                mapSubdomains:{subdomains: ['0', '1', '2', '3']},

                mainInfoId:"",
                detail:""
            }

        },
        methods:{
            getDetail(){
                let url = '/earthApi/shootPlan/getShootPlanDetail?infoId='+this.mainInfoId;
                this.$http.get(url).then(response => {
                    this.detail = response.body.data;

                    let geo = JSON.parse(this.detail.geo);
                    let polygonPointList = [];
                    for(let i=0;i<geo.coordinates[0].length;i++){
                        polygonPointList.push([geo.coordinates[0][i][1],geo.coordinates[0][i][0]])
                    }
                    L.polygon(polygonPointList, {color: 'transparent',fillColor:'#E02020',fillOpacity:0.38}).addTo(this.$refs.shootPlanPageMap.mapObject);

                    setTimeout(()=>{
                      this.$refs.shootPlanPageMap.mapObject.fitBounds(L.geoJSON(JSON.parse(this.detail.geoBound)).getBounds())
                    },1000)

                })

            },

        },
        mounted(){
            this.mainInfoId = this.$route.query.mainInfoId;
            this.getDetail();
        }


    }

</script>

<style scoped>

</style>
