<template>
    <div id="planeRecognition">
        <l-map ref="map" :maxBounds="maxBounds" :zoom="zoom" :center="center" :options="option">
            <l-tile-layer v-if="mapShow" ref="mapLayer" url="https://tile.charmingglobe.com/tile/world/tms/{z}/{x}/{y}?v=v1&token=Bearer a84a40c81f784490a4c5689187054abf" :tms="true" />
            <l-tile-layer v-if="citeShow" :url="citeUrl" :options="{tms:true}" :transparent="true" />
            <template v-for="item in bubbleList.traffics">
                <l-custom-marker
                    v-if="trafficShow"
                    :key="item.trafficId"
                    :imgUrl="item.trafficType == 1 ? busIconUrl:subwayIconUrl"
                    :color1="'#ffffff'"
                    :color2="'#00000080'"
                    :latlon=[item.lat,item.lon]
                    :title="item.title"
                    :width="83"
                    :index="1000"
                    @click="clickBubble(item.trafficId)"
                />
            </template>
            <l-custom-marker
                v-if="planeShow"
                :key="this.bubbleList.airportIntroduce.infoId"
                :imgUrl="this.bubbleList.airportIntroduce.thumb"
                :color1="'#ffffff'"
                :color2="'#00000080'"
                :latlon=[this.bubbleList.airportIntroduce.lat,this.bubbleList.airportIntroduce.lon]
                :title="this.bubbleList.airportIntroduce.title"
                :width="83"
                :index="1000"
                @click="clickBubble('wiki')"
            />
        </l-map>
        <div v-if="share == 1" class="titleBar">
            {{planeDetail.mainTitle}}
        </div>
        <div id="scanner" :style="scannerShow? 'transform: translateY(2000px);transition: transform 4s;':''"></div>
        <TopShareBar v-if="share ==1" />
        <div v-if="share==1&&shareTip" id="shareTip">打开共生地球App,体验更多功能</div>
    </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer} from 'vue2-leaflet';
import busIconUrl from "../../assets/Plane/bus.png";
import subwayIconUrl from "../../assets/Plane/subway.png";
import TopShareBar from '@/components/TopShareBar';
import LCustomMarker from "@/components/Vue2LeafletCustomMarker"
export default {
    name: "PlaneRecognition",
    components:{
        LMap,
        LTileLayer,
        // LMarker,
        TopShareBar,
        LCustomMarker
    },
    data(){
        return{
            zoom: 12,
            center: L.latLng( 40.074, 116.604),
            maxBounds: L.latLngBounds([[85,-180], [-85,180]]),
            option: {zoomControl: false, attributionControl: false, renderer: L.canvas()},
            mapSubdomains:{subdomains: ['0', '1', '2', '3']},
            citeShow:false,
            citeUrl:'',
            infoId:"202110191709000475521",
            trafficShow:false,
            planeShow:false,
            bubbleList:{
                airportIntroduce:{
                    context: "",
                    infoId: "",
                    introductId: "",
                    lat: 40.074,
                    lon: 116.604,
                    pictureList: "",
                    thumb: "",
                    titile: ""
                },
                traffics:[
                    {
                        code: "",
                        color: "",
                        color1: "",
                        infoId: "",
                        lat: 40.077,
                        lon: 116.605,
                        latlon:L.latLng( 43.97686087712015,125.39486203271193),
                        title: "",
                        trafficId: "",
                        trafficType: 2
                    },
                ]
            },
            resetPosition:[40.074,116.604],
            resetZoom:12,
            busIcon:L.icon(
                {
                    iconUrl:busIconUrl,
                    iconSize:  [50, 53],
                    iconAnchor:[25,50]
                }
            ),
            busIconUrl:busIconUrl,
            subwayIcon:L.icon(
                {
                    iconUrl: subwayIconUrl,
                    iconSize:  [50, 53],
                    iconAnchor:[25,50]
                }
            ),
            subwayIconUrl:subwayIconUrl,
            planeIcon:L.divIcon(
                {
                }
            ),
            planeMarker:L.marker(
                [40.074,116.604]
            ),
            planeId:"324",
            share:0,//是否分享 0不分享 1分享
            planeDetail:{},//飞机详情
            scannerShow: false,
            shareTip: false,
            mapShow: false,
        }
    },
    methods:{
        toggleCite(){
            if(!this.citeShow){
                this.scannerShow = true
                setTimeout(()=>{this.setCite(); this.scannerShow = false;},1200)
            } else {
                this.hideCite()
            }
        },
        setCite(){
            this.citeShow = true
        },
        hideCite(){
            this.citeShow = false;
        },
        getBubble(){
            this.bubbleList.traffics=[]
            this.bubbleList.airportIntroduce = {}
            this.$http.get('/earthApi/app-airplane-cite/getTrafficsAndIntroduce?infoId='+this.infoId).then(response => {
                    if(response.data.status === 200){
                        this.mapShow = true
                        for(let i = 0; i<response.body.data.traffics.length;i++){
                            let Position = {
                                code: response.body.data.traffics[i].code,
                                color: response.body.data.traffics[i].color,
                                color1: response.body.data.traffics[i].color1,
                                infoId: response.body.data.traffics[i].infoId,
                                lat: response.body.data.traffics[i].lat,
                                lon: response.body.data.traffics[i].lon,
                                title: response.body.data.traffics[i].title,
                                trafficId: response.body.data.traffics[i].trafficId,
                                trafficType: response.body.data.traffics[i].trafficType,
                                latlon:L.latLng(response.body.data.traffics[i].lat,response.body.data.traffics[i].lon)
                            }
                            this.bubbleList.traffics.push(Position)
                        }
                        this.planeId =response.body.data.airportIntroduce.infoId
                        this.bubbleList.airportIntroduce = response.body.data.airportIntroduce
                        this.planeShow = true
                        this.trafficShow = true;
                        document.title = response.body.data.title;
                        if(window["Title"]){
                            window["Title"].postMessage(response.body.data.title);
                        }
                        this.resetPosition = [response.body.data.lat,response.body.data.lon]
                        this.resetZoom = response.body.data.zoom
                        this.$refs.map.mapObject.setView(this.resetPosition,this.resetZoom)
                        this.$refs.map.mapObject.setMaxBounds(L.geoJSON(JSON.parse(response.body.data.maxGeo)).getBounds())
                        this.$refs.map.mapObject.setMinZoom(response.body.data.maxZoom)
                        this.citeUrl = 'https://map.charmingglobe.com/tile/dataset/'+response.body.data.cite+'/{z}/{x}/{y}?token=Bearer%20a84a40c81f784490a4c5689187054abf'
                    } else {
                        this.$router.push(
                            {
                                path: '/NotExist',
                            }
                        )
                    }
                }
            )
        },

        showTraffic(){
            this.trafficShow = true
        },
        hideTraffic(){
            this.trafficShow = false
        },
        resetMap(){
            this.$refs.map.mapObject.setView(this.resetPosition,this.resetZoom)
        },
        clickBubble(val){
            if(this.share == 1){
                this.shareTip = true
                setTimeout(()=>{this.shareTip = false},3000)
            } else {
                if(window["OpenTraffic"]){
                    window["OpenTraffic"].postMessage(val);
                }
            }
        },
        hidePlane(){
            this.planeShow = false
            // this.$refs.map.mapObject.removeLayer(this.planeMarker)
        },
        showPlane(){
            this.planeShow = true
        }
    },
    mounted() {
        window.leaflet = this;
        this.infoId = this.$route.query.infoId
        this.share = this.$route.query.share
        this.getBubble()
    }
}
</script>

<style lang="scss" scoped>

#planeRecognition {
    font-family: 微软雅黑;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}
.titleBar {
    width: 100%;
    height: 22px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
    top: 45px;
    z-index: 9999;
    line-height: 22px;
    text-align: center;
    color: white;
    font-weight: bold;
}
.bottomBar {
    width: 100%;
    height: 64px;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;

    #openApp {
        color: white;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        line-height: 32px;
        margin: 16px 24px;
        height: 32px;
        background: #675cb8;
        border-radius: 18px;
    }
}

#scanner{
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    top: -152px;
    height: 150px;
    background: linear-gradient(180deg,rgba(0,0,0,0.00), #99c0e0);
    border-bottom: #5aa6e4 solid 2px;
}

#shareTip{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 244px;
    height: 60px;
    background-color: rgba(0,0,0,0.6);
    border-radius: 11px;
    line-height: 60px;
    font-size: 14px;
    color: white;
    text-align: center;
    z-index: 9999;
}

</style>
