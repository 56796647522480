<template>
    <div class="content">
        <!--    <div @click="collectionDetail" style="color: yellow;font-size: 32px">xjhxjh</div>-->
        <!--    <product1 ></product1>-->
        <swiper v-if="products.length>0"  ref="mSwiper" style="width: 100%;height: 100%;" :options="swiperOptions" @slideChange="onPageChange">
            <swiper-slide
                    v-for="(item,index) in productsTypeList"
                    :key="index"
                    >
              <img v-show="item === 1" style="width: 100%;height: 100%;z-index: -3;position: absolute;"  :src="products[0].picture" alt="">

<!--              product2-->
                <!-- 个人信息展示页 -->
              <div v-show="item === 2" style="width: 100%;height: 100%;overflow-y: scroll">
                <!-- 个人信息展示页 -->
                <img style="width: 100%;height: 100%;z-index: -3;position: absolute;top: 0;" :src="products[productIndex].backPicture" alt="">

                  <div style="width: 100%;height: 100%;">
                    <div style="width:95% ;height: 100%;padding-top: 10px;margin: 0 auto;">
                      <div style="color: white;font-size: 26px;width: 100%; text-align: center;margin-top: 10px;margin-bottom: 15px;" class="title">{{products[ productIndex ].title}}</div>
                      <div style="width: 100%;height: 500px;margin: 0 auto;position: relative;">
                        <!-- 相框 -->
                        <img style="width: 370px;height:490px;z-index: -1;position: absolute;top: 0;display: block;left: 50%;transform: translateX(-50%);" src="../../assets/QuickFlash/product_bg.png">
                        <div style="width: 100%;height: 30px;"></div>
                        <!-- 个性化图片 -->
                        <div  @click="selectimg()" style="width:300px;margin: 0 auto;height: 460px;">
                          <img style="width:95%;display: block;height: 95%;border-radius: 10px;margin: 0 auto;" :src="personalPicUrlList[productIndex - 1]">
                        </div>
                      </div>
                      <div style="position: relative;color: white;font-size: 24px; text-align: left;margin:0 auto ;width: 90%;" class="title">{{products[ productIndex ].context}}</div>
                      <!--				<div v-show="productUserName !== ''" style="color: white;font-size: 24px;width: 100%; text-align: right;float: right;margin-right: 20px;" class="title">{{'——' + productUserName}}</div>-->
                      <div v-show="products[ productIndex ].name !== ''" style="color: white;font-size: 24px;width: 100%; text-align: right;float: right;margin-right: 20px;" class="title">{{'——' + products[ productIndex ].name}}</div>
                    </div>
               </div>
              </div>
<!--              productt3-->
              <div v-show="item === 3" style="width: 100%;height: 100%;overflow-y: scroll">
                <div style="width: 100%;" :style="{height: `${statusBarHeight+titleBarHeight}px`}"></div>
                <img style="width: 100%;height: 100%;z-index: -3;position: absolute;top: 0;" :src="products[productIndex].backPicture">

                <div style="width: 100%;height: 100%;">
                  <div style="width:95% ;height: 100%;padding-top: 10px;margin: 0 auto;">
                    <div style="color: white;font-size: 26px;width: 100%; text-align: center;margin-top: 10px;margin-bottom: 15px;" class="title">{{products[ productIndex ].title}}</div>
                    <div style="width: 100%;height: 500px;margin: 0 auto;position: relative;">
                      <!-- 相框 -->
                      <img style="width: 370px;height:490px;z-index: -1;position: absolute;top: 0;display: block;left: 50%;transform: translateX(-50%);" src="../../assets/QuickFlash/product_bg.png">
                      <div style="width: 100%;height: 30px;"></div>
                      <!-- 个性化图片 -->
                      <div style="width:300px;margin: 0 auto;height: 460px;">
                        <img style="width:95%;display: block;height: 95%;border-radius: 10px;margin: 0 auto;" :src="products[productIndex].picture">
                      </div>
                    </div>
                    <div style="color: white;font-size: 26px;width: 100%; text-align: center;margin-top: 10px;margin-bottom: 15px;" class="title">{{products[productIndex ].context}}</div>
                  </div>
                </div>
              </div>
<!--           product4-->
              <div v-show="item === 4">
                <img style="width: 100%;height: 100%;z-index: -3;position: absolute;top: 0;"  :src="products[productIndex].backPicture">
                <div style="width: 100%;font-size: 26px;color: white;line-height: 30px;text-align: center;margin-top: 8%;" class="title">专属数字藏品</div>
                <div style="width: 100%;height:15%;"></div>
                <div style="width: calc(100% - 35px);margin:0 auto;">
                  <div style="position: relative;height: 300px;">
                    <img style="width: 60%; display: block;position: absolute;bottom: 0px;left: 50%;transform: translate(-50%, 0);"  :src="products[productIndex].picture">
                    <img style="width: 80%;height: 40px; display: block;position: absolute;bottom: 0px;left: 50%;transform: translate(-50%,0);" src="../../assets/QuickFlash/wheat.png">
                  </div>
                  <div style="height: 10px"></div>
                  <!-- 椭圆盘 -->
                  <div id="bottomPlate"></div>
                </div>
                <div @click="openDetail" style="position: absolute;left: 50%;bottom: 12%;transform: translateX(-50%);width: 192px;height: 50px; border-radius: 8px;
			background-color: #F8D285;font-size: 18px;font-weight: 700;color: #4A4A4A;line-height: 50px;text-align: center;">查看藏品详情</div>
              </div>

<!--              product5-->
              <div v-show="item === 5">
                <img style="width: 100%;height: 100%;z-index: -3;position: absolute;top: 0;"  :src="products[productIndex].backPicture">
                <div style="width: 100%;font-size: 22px;color: white;line-height: 30px;text-align: center;white-space:pre-wrap;margin-top: 18%;font-family:YouSheBiaoTiHei;" v-text="products[productIndex].context"></div>
                <div @click="seeAgain" style="position: absolute;left: 50%;bottom: 20%;transform: translateX(-50%);width: 192px;height: 50px; border-radius: 8px;background-color: rgba(255,255,255,0.52);font-size: 18px;font-weight: 700;color: white;line-height: 50px;text-align: center;">再看一次</div>
                <!--		<button v-show="btnText !== '分享'" @click="downLoadApp" style="position: absolute;left: 50%;bottom: 12%;transform: translateX(-50%);width: 192px;height: 50px; border-radius: 8px; background-color: #F8D285;font-size: 18px;font-weight: 700;color: #4A4A4A;line-height: 50px;text-align: center;">{{btnText}}</button>-->
                <!--		<button v-show="btnText === '分享'" @click="shareProducts" style="position: absolute;left: 50%;bottom: 12%;transform: translateX(-50%);width: 192px;height: 50px; border-radius: 8px; background-color: #F8D285;font-size: 18px;font-weight: 700;color: #4A4A4A;line-height: 50px;text-align: center;">{{btnText}}</button>-->
              </div>

<!--                <product1 v-show="item === 2"></product1>-->
<!--              <product2 ref="child" v-show="productsTypeList[index] === 2"></product2>-->
<!--                <product3 v-show="item == 3"></product3>-->
<!--                <product4 v-if="productsTypeList[index] === 4"></product4>-->
<!--                <product5 v-if="productsTypeList[index] === 5"></product5>-->
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

    </div>
</template>

<script>
    //页面引入swiper
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    // safari通过组件的方式引用会报错
    // import product1 from './ProductComponents/product1'
    // import product2 from './ProductComponents/product2'
    // import product3 from './ProductComponents/product3'
    // import product4 from './ProductComponents/product4'
    // import product5 from './ProductComponents/product5'

    export default {
        mounted() {
            console.log("快闪产品列表")
            window.ProductMain = this;
            var productType = this.$route.query.productType
            this.$store.commit('setProductType',productType)
            this.$store.commit('setUserId',this.$route.query.userId)
            this.$store.commit('setMainId',this.$route.query.mainId)
            this.$store.commit('setProductUserName',this.$route.query.userName)
            console.log(this.mainId)
            this.getFastshootProduct()
            this.$store.commit('setProductIndex',0)
        },
        components: {
            swiper,
            swiperSlide,
            // product1,
            // product2,
            // product3,
            // product4,
            // product5,
        },
        data() {
            return {
                flag:true,
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                        clickable: true,            //轮播按钮支持点击
                    }
                }
            }
        },

        methods: {
            changeIndex(){
                console.log(this.$refs.mSwiper.swiper)
                this.$refs.mSwiper.swiper.slideTo(0, 1000, true);//切换到第一个slide，速度为1秒

            },
            collectionDetail(){
                this.$router.push('collectionDetail')
            },
            onPageChange(){
                this.$store.commit('setProductIndex',this.$refs.mSwiper.swiper.activeIndex)
            },
            updateImage(url){
                // this.$refs.child.updateImage2(url)
                // var tempList = this.personalPicUrlList
                this.flag = false
                // console.log( '更新更新更新更新更新更新更新更新')
                // console.log( this.personalPicUrlList)
                this.getFastshootProduct()
                // tempList[this.productIndex - 1] = url
                // this.$store.commit('setPersonalPicUrlList',tempList)
                // this.$store.commit('setUpdatePersonalPic',url)
                console.log(url)
                // this.flag = true
            },
            getFastshootProduct(){
                console.log('************************************')
                console.log(this.mainId)
                console.log(this.userId)
                let _this = this
                this.$http.get('/earthApi/fast-shoot/getFastshootProduct?mainId='+this.mainId+'&userId='+this.userId).then(res=> {
                    // this.$http.get('/quickFlash/getFastshootProduct?mainId=324614686571&userId=1634696758966529').then(res=> {
                    console.log(res)
                    console.log(res["data"].data.fastShootProductList)
                    if(res["data"].status === 200){
                        let productsTypeList = [];
                        _this.$store.commit('setProducts',res["data"].data.fastShootProductList)
                        console.log('this.products')
                        console.log(this.products)
                        _this.$store.commit('setProductTitle',res["data"].data.title)
                        let picList = [];
                        for(let i=0; i<res["data"].data.fastShootProductList.length; i++){
                            productsTypeList.push(res["data"].data.fastShootProductList[i].type)
                            if(res["data"].data.fastShootProductList[i].type === 2){
                                // console.log('获取个性图片')
                                // console.log(res["data"].data.fastShootProductList[i].picture)
                                picList.push(res["data"].data.fastShootProductList[i].picture)
                                // console.log(picList)

                            }
                        }
                        _this.$store.commit('setProductsTypeList',productsTypeList)
                        _this.$store.commit('setPersonalPicUrlList',picList)
                        // console.log('得到个性图片')
                        // console.log( this.personalPicUrlList)

                    }
                })
            },
          selectimg(){
            if(this.productType === '1'){
              console.log('选择图片')
              if(window["ChooseImage"]){
                window["ChooseImage"].postMessage("");
              }
            }else{
              return
            }
            // console.log('productIndex')
            // console.log(this.productIndex)
            // this.selectPicture = true
            //仅线上或线下可以编辑
            // if(this.productType !==2){
            // uni.chooseimg({
            //   count: 1,
            //   sizeType: ['original', 'compressed'],
            //   sourceType: ['album'], //这要注意，camera掉拍照，album是打开手机相册
            //   success: (chooseimgRes)=> {
            // 	  const tempFilePaths = chooseimgRes.tempFilePaths;
            // 	this.upLoad(tempFilePaths[0])
            //   },
            // })
            // }else{
            //
            // }
          },
          openDetail(){
            this.$router.push('collectionDetail')
          },
          seeAgain(){
            this.changeIndex()
            // eslint-disable-next-line no-mixed-spaces-and-tabs
            this.$store.commit('setProductIndex',0)
          },
        },
        computed:{
            statusBarHeight: {
                get() {
                    return this.$store.state.statusBarHeight
                },
                set(newValue) {
                    return newValue;
                },
                // return this.$store.state.statusBarHeight

            },
            titleBarHeight: {
                get() {
                    return this.$store.state.titleBarHeight
                },
                set(newValue) {
                    return newValue;
                },
                // return this.$store.state.titleBarHeight
            },
            token() {
                return this.$store.state.token
            },
            userId() {
                return this.$store.state.userId
            },
            mainId() {
                return this.$store.state.mainId
            },
            productUserName() {
                return this.$store.state.productUserName
            },
            products() {
                return this.$store.state.products
            },
            productType() {
                return this.$store.state.productType
            },
            productsTypeList() {
                return this.$store.state.productsTypeList
            },
            productTitle() {
                return this.$store.state.productTitle
            },
            productIndex() {
                return this.$store.state.productIndex
            },
            current() {
                return this.$store.state.current
            },
            personalPicUrlList() {
                return this.$store.state.personalPicUrlList
            },

        },

    }
</script>

<style>

    .content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: yellow;
        z-index: -1;
    }
    .swiper-pagination {
        /*background-color: #F29B76;*/
    }
    .navigator {
        font-size: 14px;
        line-height: 24px;
    }


</style>

