<template>
    <div style="width: 100%; height: 100%; overflow: auto; -webkit-overflow-scrolling: touch;">
        <img v-if="detail.detailType == 0" :src="detail.detailPicture" width="100%"/>

        <div v-if="detail.detailType == 1">
<!--            <div style="height: 100px;width: 100%; background: #f6f6f6"></div>-->
            <div v-html="detail.contextDetail"></div>
        </div>
        <ShareButton />
        <vue-danmaku ref="danmaku" :danmus="detail.commentList" useSlot loop :speeds="50" :channels="5" randomChannel :debounce="2000">
            <div></div>
            <template slot="dm" slot-scope="{ index, danmu }">
                <div class="danmu">
                    <div class="tag">
                        {{ danmu.commentContent }}
                    </div>
                    <div class="icon">
                        <img :src="danmu.avatar"/>
                    </div>
                </div>
            </template>
        </vue-danmaku>
    </div>
</template>

<script>

import vueDanmaku from 'vue-danmaku'
import ShareButton from "@/components/ShareButton";
export default {
    name: "CrowdShootDetail",
    components: {
        ShareButton,
        vueDanmaku
    },
    data(){
        return{
            detail:{
                commentList: [
                ],
                contextDetail: "",
                detailPicture: "",
                detailType: 0,
                status:0,
            },
            infoId: 202110251131000111804,
        }
    },
    methods: {

        load() {
            this.$http.get('/earthApi/crowd-funding/getCrowdDetail2?infoId=' + this.infoId).then(response => {
                this.detail = response.body.data;
                if(this.detail.detailType == 0){
                    // eslint-disable-next-line no-undef
                    IsPicture.postMessage('')
                }
                if(this.detail.status == 1){
                    // eslint-disable-next-line no-undef
                    Complete.postMessage('')
                }
            })
        },

        sendDanmaku(val){
            this.$refs.danmaku.add(JSON.parse(val))
        }
    },
    mounted() {
        window.detail = this;
        this.infoId = this.$route.query.infoId
        this.load();
    }
}
</script>

<style scoped lang="scss">

.vue-danmaku{
    height: 60%;
    position: fixed;
    top: 100px;
    width: 100%;
    pointer-events: none;
}


.danmu{
    margin-top: 60px;
    width: unset;
    .icon {
        width: 32px;
        height: 32px;
        border: 2px solid white;
        border-radius: 50%;
        box-shadow: 1px 2px 4px 0px rgba(0,0,0,0.25);
        margin-top:-30px;
        background-color: white;
        img {
            margin-top: 1px;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            object-fit:cover;
        }
    }
    .tag {
        color: rgba(74,74,74,1);
        font-size: 10px;
        line-height:22px;
        font-weight: 400;
        padding-left: 20px;
        padding-right: 8px;
        text-align: left;
        margin-left:16px;
        height: 22px;
        opacity: 1;
        background: #f6f6f6;
        border: 2px solid white;
        border-radius: 13px;
        box-shadow: 0px 2px 4px 0px rgba(211,211,211,0.50);
    }
}

</style>