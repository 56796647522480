<template>
  <div class="content">
    <img style="width: 100%;height: 100%;z-index: -3;position: fixed;top: 0;"  src="https://market.obs.cn-north-4.myhuaweicloud.com:443/202204221628000413682.jpg">
    <div style="width: 100%;font-size: 26px;color: white;line-height: 30px;text-align: center;margin-top: 8%;" class="title">专属数字藏品</div>
    <div style="width: 100%;height:15%;"></div>
    <div style="width: calc(100% - 35px);margin:0 auto;">
      <div style="position: relative;height: 300px;">
        <img style="width: 60%; display: block;position: absolute;bottom: 0px;left: 50%;transform: translate(-50%, 0);"  :src="collectionDetail.collection">
        <img style="width: 80%;height: 40px; display: block;position: absolute;bottom: 0px;left: 50%;transform: translate(-50%,0);" src="../../assets/QuickFlash/wheat.png">
      </div>
      <div style="height: 10px"></div>
      <!-- 椭圆盘 -->
      <div id="bottomPlate"></div>
    </div>
    <div style="font-weight: 700;text-align: LEFT;color: #ffffff;line-height: 16px;font-size: 16px;margin-left: 25px;margin-top: 37px">{{productTitle}}</div>
    <div style="margin-left: 25px;margin-top: 15px;clear: both">
      <div style="width: 67px;height: 21px;background: #ffe0a3;border-radius: 4px;color: #252525;font-size: 12px;text-align: center;line-height: 21px;font-weight: bold;float: left">官方授权</div>
      <div style="width: 67px;height: 21px;background: #ffe0a3;border-radius: 4px;color: #252525;font-size: 12px;text-align: center;line-height: 21px;font-weight: bold;float: left;margin-left: 8px">{{collectionDetail.ntfName}}</div>
      <div style="width: 67px;height: 21px;background: #ffe0a3;border-radius: 4px;color: #252525;font-size: 12px;text-align: center;line-height: 21px;font-weight: bold;float: left;margin-left: 8px">限量发行</div>
    </div>

    <div style="height: 20px"></div>
    <div style="font-weight: 700;text-align: LEFT;color: #ffffff;line-height: 14px;font-size: 14px;margin-left: 25px;margin-top: 18px;clear: both">发行日期   {{collectionDetail.createTime}}</div>
    <div style="width: 90%;margin:10px auto 0 auto;height: 70px;background: #2e2e2e;border-radius: 10px;">
      <img style="width: 50px;height: 50px;margin-left: 11px;margin-top: 10px;border-radius: 50%;float: left" :src="collectionDetail.avatar" >
      <div style="float: left;margin-top: 16px;margin-left: 5px">
        <div style="font-size: 14px;font-family: Arial, Arial-Bold;font-weight: 700;text-align: LEFT;color: #cccccc;line-height: 14px;">创作者</div>
        <div style="font-size: 14px;font-family: Arial, Arial-Bold;font-weight: 700;text-align: LEFT;color: white;line-height: 14px;margin-top: 6px">{{collectionDetail.nick}}</div>

      </div>
    </div>

    <div style="font-weight: 700;text-align: LEFT;color: #ffffff;line-height: 14px;font-size: 14px;margin-left: 25px;margin-top: 18px;clear: both">藏品描述</div>
    <img style="width: calc(100% - 30px);margin-top: 15px;margin-left: 15px;" :src="collectionDetail.picture">
    <div @click="back" style="position: fixed;left: 50%;bottom: 10px;transform: translateX(-50%);width: 192px;height: 30px; border-radius: 8px;
			background-color: #F8D285;font-size: 18px;font-weight: 700;color: #4A4A4A;line-height: 30px;text-align: center;">返回</div>

  </div>
</template>

<script>
export default {
  mounted() {
    this.getCollectionDetail()
    },
  data() {
    return {
      collectionDetail:{}
    }
  },
  methods: {
    back(){
      this.$router.back()
    },
    getCollectionDetail(){
      // let _this = this
      this.$http.get('/earthApi/fast-shoot/getCollectionDetail?mainId='+this.mainId+'&userId='+this.userId).then(res=> {
        // this.$http.get('/quickFlash/getFastshootProduct?mainId=324614686571&userId=1634696758966529').then(res=> {
        console.log(res);
        if(res["data"].status === 200){
          this.collectionDetail = res.data.data
          console.log(this.collectionDetail)
        }
      })
    },
  },
  computed:{
    mainId() {
      return this.$store.state.mainId
    },
    userId() {
      return this.$store.state.userId
    },
    username() {
      return this.$store.state.account
    },
    products() {
      return this.$store.state.products
    },
    productTitle() {
      return this.$store.state.productTitle
    },
    productIndex() {
      return this.$store.state.productIndex
    },
    productType() {
      return this.$store.state.productType
    },

  },
}
</script>

<style>
.title{
  font-family:YouSheBiaoTiHei;
}

#bottomPlate{
  width: 278px;
  height: 43px;
  background: linear-gradient(191deg,#000000 0%, rgba(151,151,151,0.18) 100%);
  border-image: linear-gradient(180deg, #4a4a4a, rgba(216,216,216,0.00) 100%) 1 1;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.25);
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  z-index: -1;
  overflow: scroll;
}
</style>
